import React, { useEffect, useState, version } from "react";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import Milestone from "../../../assets/img/milestone.png";
import { RxCross1 } from "react-icons/rx";
import { BsArrowLeft } from "react-icons/bs";
import "./View.scss";
import { useNavigate, useParams } from "react-router-dom";
import Excel from "../../../assets/img/excel.png";
import Word from "../../../assets/img/word.png";
import Pdf from "../../../assets/img/pdf.png";
import AddEditMaintainence from "../AddForm/AddEditMaintainence";
import DeleteModal from "../../Model/DeleteModal";
import { toast } from "react-toastify";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { displayDuration } from "../../../Mixin/DisplayDuration";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import FileDisplay from "../../FileInput/FileDisplay";

const MaintenanceView = () => {
  const params = useParams();
  const { id } = params || {};
  const [maintainence, setMaintainence] = useState([]);
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [open, setOpen] = React.useState(false);
  const [DeleteMaintenencae, setDeleteMaintenencae] = useState(false);
  const [deleteImg, setDeleteImg] = useState("");
  const [loading, setLoading] = useState(false);
  const decryptedId = Decryption(id);
  const { company_id, location_id } = useFilter();

  const fetchMaintenance = () => {
    setLoading(true);
    try {
      httpService
        .get(`maintenance/get_maintenance/${decryptedId}`)
        .then((response) => {
          setMaintainence(response?.data[0]);
          setDeleteImg("");
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchMaintenance();
  }, [params?.id, showLoginPopup]);

  const handleOpen = () => {
    setOpen(true);
    fetchMaintenance();
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteImg("");
    setDeleteMaintenencae(false);
    fetchMaintenance();
  };

  const HandleDeleteMaintenance = async () => {
    if (deleteImg !== "" && deleteImg !== undefined) {
      await handleDeleteImg(deleteImg);
    } else {
      try {
        let response = await httpService.delete(
          `${"maintenance/delete_maintenance"}/${decryptedId}`
        );
        navigate("/maintenance");
        console.log(response);
        if (response) {
          toast.success("Maintenance Deleted successfully", {
            position: "top-right",
          });
          setDeleteMaintenencae(false);
          fetchMaintenance();
        }
      } catch (error) {
        setDeleteMaintenencae(false);
        console.error("Error deleting maintenance:", error);
        // toast.error(error, {
        //   position: "top-right",
        // });
      }
    }
  };

  const handleDeleteImg = async (imgId) => {
    const newArray = [...maintainence?.attachment]; // Create a copy of the attached array
    newArray.splice(imgId, 1);

    const formData = new FormData();
    for (const key in maintainence) {
      if (maintainence[key] !== null && maintainence[key] !== undefined) {
        if (
          key !== "activities" &&
          key !== "employees" &&
          key !== "departments"
        ) {
          if (key === "attachment") {
            for (let i = 0; i < newArray.length; i++) {
              formData.append(`existFile[${i}]`, newArray[i]);
            }
          } else {
            formData.append(key, maintainence[key]);
          }
        } else {
          maintainence[key]?.map((item, index) => {
            if (key === "activities") {
              formData.append(`activity_id[${index}]`, item.id);
            }
            if (key === "employees") {
              formData.append(`employee_id[${index}]`, item.id);
            }
            if (key === "departments") {
              formData.append(`department_id[${index}]`, item.id);
            }
          });
        }
      }
    }
    try {
      let response = await httpService.put(
        `${"maintenance/edit_maintenance"}/${decryptedId}`,
        formData
      );
      if (response.message) {
        setDeleteMaintenencae(false);
        fetchMaintenance();
        toast.success("File Deleted successfully", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error adding maintenance:", error);
      setDeleteMaintenencae(false);
      // toast.error(error, {
      //   position: "top-right",
      // });
    }
  };

  const duration = displayDuration(
    maintainence?.days,
    maintainence?.hours,
    maintainence?.minutes
  );

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="maintenance-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={() => navigate("/maintenance")} />
              </span>
              Maintenance Details - [
              {maintainence.name ? maintainence.name : "--"}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button
              type="submit"
              className="btn btn-edit"
              onClick={() => handleOpen()}
            >
              <CiEdit /> Edit{" "}
            </button>
            <button
              type="submit"
              className="btn btn-delete"
              onClick={() => {
                setDeleteMaintenencae(true);
              }}
            >
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            {/* <div className="col-md-2">
              <div>
                <img src={Milestone} alt="Maintenance img" />
              </div>
            </div> */}
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Maintenance Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{maintainence.name ? maintainence.name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Duration</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{duration ? duration : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s)</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{maintainence?.version ? maintainence?.version : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {maintainence.description ? maintainence.description : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Employee</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {maintainence?.employees?.length === 0 ? (
                      <div>--</div>
                    ) : (
                      maintainence?.employees?.map((employees, index) => (
                        // Use key prop to provide a unique key for each child
                        <span key={index}>{employees.name}</span>
                      ))
                    )}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Department</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {maintainence?.departments?.length === 0 ? (
                      <div>--</div>
                    ) : (
                      maintainence?.departments?.map((departments, index) => (
                        // Use key prop to provide a unique key for each child
                        <span key={index}>{departments.name}</span>
                      ))
                    )}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Workday </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>
                    {[
                      maintainence?.sunday_working_status === 1 && "Sunday",
                      maintainence?.monday_working_status === 1 && "Monday",
                      maintainence?.tuesday_working_status === 1 && "Tuesday",
                      maintainence?.wednesday_working_status === 1 &&
                        "Wednesday",
                      maintainence?.thursday_working_status === 1 && "Thursday",
                      maintainence?.friday_working_status === 1 && "Friday",
                      maintainence?.saturday_working_status === 1 && "Saturday",
                    ]
                      .filter((day) => day) // Filter out falsy values (false, null, undefined, "")
                      .join(", ") || "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Company</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>
                      {maintainence?.company_name
                        ? maintainence?.company_name
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Location</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>
                      {maintainence?.location_name
                        ? maintainence?.location_name
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Milestone</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {maintainence?.milestones?.length === 0 ? (
                      <div>--</div>
                    ) : (
                      maintainence?.milestones?.map((milestone, index) => (
                        // Use key prop to provide a unique key for each child
                        <span key={index}>{milestone.name}</span>
                      ))
                    )}
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Activity</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {maintainence?.activities?.length === 0 ? (
                      <div>--</div>
                    ) : (
                      maintainence?.activities?.map((activity, index) => (
                        // Use key prop to provide a unique key for each child
                        <span key={index}>{activity.name}</span>
                      ))
                    )}
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Rooms</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {maintainence?.rooms?.length === 0 ? (
                      <div>--</div>
                    ) : (
                      maintainence?.rooms?.map((room, index) => (
                        // Use key prop to provide a unique key for each child
                        <span key={index}>{room.name}</span>
                      ))
                    )}
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Plants</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {maintainence?.plants?.length === 0 ? (
                      <div>--</div>
                    ) : (
                      maintainence?.plants?.map((plant, index) => (
                        // Use key prop to provide a unique key for each child
                        <span key={index}>{plant.name}</span>
                      ))
                    )}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Files </label>
                    <span>:</span>
                  </div>
                </div>
                <FileDisplay
                  attached={maintainence?.attachment}
                  handleDelete={() => setDeleteMaintenencae(true)}
                  setDeleteImg={setDeleteImg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <AddEditMaintainence
          open={open}
          handleClose={() => handleClose()}
          clickedmaintenance={decryptedId}
          isAdd={false}
          fetchMaintenance={() => fetchMaintenance()}
          companyId={company_id}
          locationId={location_id}
        />
      )}
      <DeleteModal
        open={DeleteMaintenencae}
        HandleClose={() => handleClose()}
        id={deleteImg}
        HandleDelete={() => HandleDeleteMaintenance()}
      />
    </>
  );
};

export default MaintenanceView;
