import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import { displayDuration } from "../../Mixin/DisplayDuration";
import moment from "moment";

const handleDownloadMaintenanceExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchMaintenanceData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`maintenance/get_maintenances`)
        .then((response) => {
          if (response?.data) {
            const maintenanceData = response?.data?.data;
            resolve(maintenanceData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching maintenance data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const maintenanceData = await fetchMaintenanceData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Maintenance");

    // Add headers
    worksheet.addRow([
      "Maintenance Name",
      "Duration",
      "Milestone",
      "Activity",
      "Company",
      "Location",
    ]);
    worksheet.getRow(1).alignment = { horizontal: "center" }; // Center align the text

    if (
      maintenanceData !== null &&
      maintenanceData !== undefined &&
      maintenanceData.length > 0
    ) {
      maintenanceData.forEach((maintenance) => {
        worksheet.addRow([
          maintenance?.name || "",
          displayDuration(
            maintenance.days,
            maintenance.hours,
            maintenance.minutes
          ) || "",
          maintenance?.milestones?.length
            ? maintenance?.milestones
                .map((milestone) => milestone.name)
                .join(", ")
            : "",
          maintenance?.activities?.length
            ? maintenance?.activities
                .map((activity) => activity.name)
                .join(", ")
            : "",
          maintenance?.company_name || "",
          maintenance?.location_name || "",
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `WorkTask_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadMaintenanceExcel };
