import React, { useEffect, useState } from "react";
import { GoTrash } from "react-icons/go";
import { RxCross1 } from "react-icons/rx";
import { BsArrowLeft } from "react-icons/bs";
import Excel from "../../../assets/img/excel.png";
import Word from "../../../assets/img/word.png";
import Pdf from "../../../assets/img/pdf.png";
import "./View.scss";
import { useNavigate, useParams } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import dayjs from "dayjs";
import AddEditEmployee from "../AddForm/AddEditEmployee";
import { toast } from "react-toastify";
import DeleteModal from "../../Model/DeleteModal";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../Loader/FullScreenLoader";
import DefaultImg from "../../../assets/img/DefaultImg.jpg";
import { useAuth } from "../../../context/AuthProvider";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import FileDisplay from "../../FileInput/FileDisplay";

const EmployeeView = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const params = useParams();
  const [rowsData, setRowsData] = useState();
  const [open, setOpen] = useState(false);
  const [deleteEmp, setDeleteEmp] = useState(false);
  const [deleteImg, setDeleteImg] = useState("");
  const [attached, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  const decryptedId = Decryption(params?.id);

  const { company_id, location_id } = useFilter();

  const handleNavigate = () => {
    navigate("/employee");
  };
  useEffect(() => {
    fetchEmployee();
  }, [params?.id, showLoginPopup]);

  const fetchEmployee = () => {
    setLoading(true);
    httpService
      .get(`employee/get_employee/${decryptedId}`)
      .then((response) => {
        setRowsData(response?.data[0]);
        setProfileImg(response?.data[0].profile_photo);
        setFiles(response?.data[0].attachment);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  //For Profile Image
  const [img, setProfileImg] = useState(null);

  const HandleDeleteEMP = async () => {
    if (deleteImg !== "") {
      handleDeleteImg(deleteImg);
    } else {
      try {
        await httpService.delete(`${"employee/delete_employee"}/${decryptedId}`);
        navigate("/employee");
        toast.success("Employee Deleted successfully", {
          position: "top-right",
        });
        setDeleteEmp(false);
        fetchEmployee();
      } catch (error) {
        setDeleteEmp(false);
        toast.error(error, {
          position: "top-right",
        });
      }
    }
  };
  const handleDeleteImg = async (imgId) => {
    const updateImg = attached;
    const newArray = [...updateImg];
    newArray.splice(imgId, 1);

    const formData = new FormData();
    for (const key in rowsData) {
      if (key === "attachment") {
        for (let i = 0; i < newArray.length; i++) {
          formData.append(`existFile[${i}]`, newArray[i]);
        }
      } else if (key === "milestones" && rowsData[key]) {
        for (let i = 0; i < rowsData[key].length; i++) {
          formData.append(`milestone_id[${i}]`, rowsData[key][i].id);
        }
      } else if (key === "departments" && rowsData[key]) {
        continue;
      } else if (key === "activities" && rowsData[key]) {
        for (let i = 0; i < rowsData[key].length; i++) {
          formData.append(`activity_id[${i}]`, rowsData[key][i].id);
        }
      } else if (key === "department_id" && rowsData[key]) {
        for (let i = 0; i < rowsData[key].length; i++) {
          formData.append(`department_id[${i}]`, rowsData[key][i]);
        }
      } else if (key === "tasks" && rowsData[key]) {
        for (let i = 0; i < rowsData[key].length; i++) {
          formData.append(`task_id[${i}]`, rowsData[key][i].id);
        }
      } else {
        if (rowsData[key] !== null) {
          const value = key === "sort_order" ? rowsData[key] || "" : rowsData[key];
          formData.append(key, value);
        }
      }
    }
    try {
      await httpService.put(`${"employee/edit_employee"}/${decryptedId}`, formData);
      setDeleteEmp(false);
      fetchEmployee();
      toast.success("File Deleted successfully", {
        position: "top-right",
      });
    } catch (error) {
      console.error("Error adding employee:", error);
      setDeleteEmp(false);
      toast.error(error, {
        position: "top-right",
      });
    }
  };

  console.log("rowsData : ", rowsData);

  return (
    <>
      {loading ? <FullScreenLoader /> : null}
      <div className="employee-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={handleNavigate} />
              </span>
              Employee Details - [{rowsData?.name ? rowsData?.name : "--"}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button onClick={() => setOpen(true)} className="btn btn-edit">
              <CiEdit /> Edit
            </button>
            <button
              onClick={() => setDeleteEmp(true)}
              className="btn btn-delete"
              disabled={rowsData?.role_id === 1 ? true : false}>
              <GoTrash /> Delete
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-2 mb-4 mb-md-0">
              <div>
                <img src={img ? img : DefaultImg} alt="employee profile image" />
              </div>
            </div>
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Employee Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.name ? rowsData?.name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Employee Code </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.employee_code ? rowsData?.employee_code : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Role </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.role_name ? rowsData?.role_name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Birthday </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.birthday ? dayjs(rowsData?.birthday).format("D MMM YYYY") : ""}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Date of Joining </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.date_of_joining ? dayjs(rowsData?.date_of_joining).format("D MMM YYYY") : ""}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Email Address </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.email}</p>
                </div>

                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Phone Number</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.phone}</p>
                </div>

                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Workday </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>
                    {[
                      rowsData?.sunday_working_status === 1 && "Sunday",
                      rowsData?.monday_working_status === 1 && "Monday",
                      rowsData?.tuesday_working_status === 1 && "Tuesday",
                      rowsData?.wednesday_working_status === 1 && "Wednesday",
                      rowsData?.thursday_working_status === 1 && "Thursday",
                      rowsData?.friday_working_status === 1 && "Friday",
                      rowsData?.saturday_working_status === 1 && "Saturday",
                    ]
                      .filter((day) => day) // Filter out falsy values (false, null, undefined, "")
                      .join(", ") || "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Company Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.company_name ? rowsData?.company_name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s) </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.version ? rowsData?.version : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Location Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.location_name ? rowsData?.location_name : "--"}</p>
                </div>

                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Milestone </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {rowsData?.milestones?.length > 0 ? (
                      rowsData.milestones.map((milestone, index) => (
                        <span key={index}>{milestone.name ? milestone.name : "--"}</span>
                      ))
                    ) : (
                      <span>--</span>
                    )}
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Activities </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {rowsData?.activities?.length > 0 ? (
                      rowsData.activities.map((activities, index) => (
                        <span key={index}>{activities.name ? activities.name : "--"}</span>
                      ))
                    ) : (
                      <span>--</span>
                    )}
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Tasks </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {rowsData?.tasks?.length > 0 ? (
                      rowsData.tasks.map((tasks, index) => <span key={index}>{tasks.name ? tasks.name : "--"}</span>)
                    ) : (
                      <span>--</span>
                    )}
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Department </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {rowsData?.departments?.length > 0 ? (
                      rowsData.departments.map((department, index) => (
                        <span key={index}>{department.name ? department.name : "--"}</span>
                      ))
                    ) : (
                      <span>--</span>
                    )}
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">{rowsData?.description ? rowsData?.description : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Version </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.version ? rowsData?.version : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Files </label>
                    <span>:</span>
                  </div>
                </div>
                <FileDisplay
                  attached={rowsData?.attachment}
                  handleDelete={() => setDeleteEmp(true)}
                  setDeleteImg={setDeleteImg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <AddEditEmployee
          open={open}
          setOpen={(e) => {
            setOpen(e);
            // SetEditData("");
          }}
          id={decryptedId}
          editData={rowsData}
          fetchEmployee={() => fetchEmployee()}
          companyId={company_id}
          locationId={location_id}
        />
      )}
      <DeleteModal
        open={deleteEmp}
        HandleClose={(e) => setDeleteEmp(e)}
        id={deleteImg}
        HandleDelete={() => HandleDeleteEMP()}
      />
    </>
  );
};

export default EmployeeView;
