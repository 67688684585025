import React, { useEffect, useState } from "react";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import { HiOutlineEye } from "react-icons/hi2";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { RxPlus } from "react-icons/rx";
import "./Room.scss";
import AddEditRoom from "./AddEditRoom";
import DeleteRoom from "./DeleteRoom";
import { useNavigate } from "react-router-dom";
import { Box, Button, Checkbox, IconButton, MenuItem, Popover, gridClasses, styled } from "@mui/material";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { Encryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import { handleDownloadRoomExcel } from "../DownloadRooms";
import { SiMicrosoftexcel } from "react-icons/si";
import { toast } from "react-toastify";

const Room = () => {
  const [open, setOpen] = React.useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [clickedRoom, setClickedRoom] = useState("");
  const [status, setstatus] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const { company_id, location_id } = useFilter();
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState("");
  const [page, setPage] = useState(0);
  const [columnName, setColumnName] = useState("");
  const [sortOrder, setsortOrder] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [allIds, setAllIds] = useState(0);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setIsAdd(true);
    setClickedRoom("");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenDelete(false);
    setIsAdd(false);
    setClickedRoom("");
  };
  const getRooms = () => {
    setLoading(true);
    httpService
      .get(
        `rooms?page=${page + 1}&pageSize=${pageSize}&search=${searchData}&sortBy=${columnName}&sortOrder=${sortOrder}`
      )
      .then((res) => {
        const data = [];
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((item, index) => {
            const rowData = {
              id: index + 1,
              roomid: item?.id,
              name: item?.name,
              milestone: item?.milestones,
              activities: item?.activities,
              status: item?.status,
              company_name: item?.company_name,
              location_name: item?.location_name,
            };
            data.push(rowData);
          });
        setRooms(data);
        // setSearchData(response?.data);
        setRowCount(res?.data?.pagination?.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getRooms();
  }, [open, company_id, location_id, showLoginPopup, page, pageSize, searchData, columnName, sortOrder]);

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  const onDownloadClick = async () => {
    try {
      await handleDownloadRoomExcel(setShowLoginPopup);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };

  const handleHeaderCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const allIds = rooms.map((row) => row.id); // Get all the IDs from the data
      setSelectedId(allIds); // Select all IDs
      console.log("Total selected IDs: ", allIds.length);
      setAllIds(allIds.length);
    } else {
      setSelectedId([]); // Deselect all
      console.log("Total selected IDs: 0");
    }
    setIsAllSelected(isChecked);
  };

  const deleteSelectedRooms = async () => {
    if (selectedId?.length > 0) {
      try {
        const response = await httpService.post("rooms/delete_all_room", { roomIds: selectedId });
        if (response?.success) {
          const messages = response?.data?.combinedMessage ? response?.data?.combinedMessage : response?.data?.message;
          response?.data?.combinedMessage
            ? toast.error(messages, {
                position: "top-right",
              })
            : toast.success(messages, {
                position: "top-right",
              });
        } else {
          throw response;
        }
        setSearchData("");
        getRooms();
        setIsAllSelected();
        setSelectedId();
        setAllIds();
      } catch (error) {
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    } else {
      toast.error("Please select atleast one plant", {
        position: "top-right",
      });
    }
  };

  const isIdSelected = (id) => {
    return selectedId?.includes(id);
  };

  const toggleId = (id) => {
    setSelectedId((prev) => {
      if (prev.includes(id)) {
        const updatedSelectedIds = prev.filter((item) => item !== id);
        if (updatedSelectedIds.length < allIds) {
          setIsAllSelected(false);
        }
        return updatedSelectedIds;
      } else {
        const updatedSelectedIds = [...prev, id];
        if (updatedSelectedIds.length === allIds) {
          setIsAllSelected(true);
        }
        return updatedSelectedIds;
      }
    });
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="room">
        <div className="page-title">
          <div className="title-wrap">
            <h1>Rooms</h1>
          </div>
          <div className="button-wrap">
            <button type="submit" className="btn btn-primary" onClick={() => handleOpen()}>
              <RxPlus /> Add Room{" "}
            </button>
          </div>
        </div>
        <div className="table-filter-wrap d-flex justify-content-between">
          <Button className="btn btn-delete" onClick={() => deleteSelectedRooms()}>
            Delete
          </Button>
          <div className="d-flex flex-row">
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDownloadClick()}
              startIcon={<SiMicrosoftexcel />}
              className="me-3">
              Download
            </Button>
            <div className="header_search-wrap">
              <div className="header_search_bar common_content">
                <div className="input-group input_group">
                  <button className="btn search-btn search_btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21 21" fill="none">
                      <circle
                        cx="9.98856"
                        cy="9.98856"
                        r="8.98856"
                        stroke="#495057"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.2402 16.7071L19.7643 20.222"
                        stroke="#495057"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <input
                    type="search"
                    className="form-control form_control"
                    placeholder="Search..."
                    aria-label="Search"
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchData(value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <button type="submit" className="btn filter-btn">
              <img src={Filter} alt="" /> Filters{" "}
            </button>
          </div> */}
        </div>

        <div
          style={{
            height: `${rooms?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}>
          <DataGrid
            className="room-table"
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              rooms?.length > 0 &&
              rooms?.map((item, index) => ({
                ...item,
                id: item.id,
                sequenceNumber: index + 1,
              }))
            }
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "select_All",
                headerName: "Select All",
                minWidth: 150,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  console.log("params : ", params);
                  return (
                    <Checkbox
                      value="Checked"
                      checked={isAllSelected ? true : isIdSelected(params.row.roomid) ? true : false}
                      onChange={() => toggleId(params.row.roomid)}
                    />
                  );
                },
                headerCheckboxSelection: true,
                renderHeader: (params) => {
                  return (
                    <>
                      <Checkbox checked={isAllSelected} onChange={(e) => handleHeaderCheckboxChange(e)} />
                      <strong>Select All</strong>
                    </>
                  );
                },
              },
              {
                field: "name",
                headerName: "Room Name",
                flex: 1,
                minWidth: 200,
              },
              {
                field: "milestone",
                headerName: "Milestone",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <span>
                      {params.row?.milestone?.length > 0
                        ? params.row?.milestone?.map((obj) => obj.name).join(", ")
                        : "--"}
                    </span>
                  );
                },
              },
              {
                field: "activities",
                headerName: "Activity",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <span>
                      {params.row?.activities?.length > 0
                        ? params.row?.activities?.map((obj) => obj.name).join(", ")
                        : "--"}
                    </span>
                  );
                },
              },
              {
                field: "status",
                headerName: "Status",
                width: 230,
                align: "center",
                renderCell: (params) => (
                  <div className="file-weap">
                    {params.value === "Inspection" && <span className="dark-blue-tag">{params.value}</span>}
                    {params.value === "In use" && <span className="green-tag">{params.value}</span>}
                    {params.value === "Free" && <span className="blue-tag">{params.value}</span>}
                    {params.value === "Harvesting" && <span className="yellow-tag">{params.value}</span>}
                    {params.value === "Transplanting" && <span className="red-tag">{params.value}</span>}
                    {params.value === "Environment Control" && <span className="dark-green-tag">{params.value}</span>}
                  </div>
                ),
              },

              {
                field: "company_name",
                headerName: "Company",
                width: 200,
                sortable: true,
                renderCell: (params) => {
                  const company = params.value || "--"; // Use a hyphen if location is falsy
                  return <div>{company}</div>;
                },
              },
              {
                field: "location_name",
                headerName: "Location",
                width: 200,
                sortable: true,
                renderCell: (params) => {
                  const company = params.value || "--"; // Use a hyphen if location is falsy
                  return <div>{company}</div>;
                },
              },
              {
                field: "action",
                headerName: "Actions",
                headerAlign: "center",
                align: "center",
                width: 100,
                renderCell: ({ row }) => (
                  <>
                    <div className="action_icon-weap">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e), setDataId(row.roomid);
                        }}>
                        <GridMoreVertIcon />
                      </IconButton>
                    </div>
                    {anchorEl && dataId === row?.roomid && (
                      <Popover
                        className="popup-menu"
                        key={dataId}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}>
                        <MenuItem
                          onClick={() => {
                            const urlEncodedId = Encryption(dataId);
                            // Navigate to the encrypted URL
                            navigate(`view/${urlEncodedId}`);
                          }}
                          className="menu-list-items">
                          <div className="menu-list-items-text">View</div>
                          <HiOutlineEye style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setIsAdd(false);
                            setClickedRoom(dataId);
                            setOpen(true);
                            handleCloseMenu();
                          }}
                          className="menu-list-items">
                          <div className="menu-list-items-text">Edit</div> <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setClickedRoom(dataId);
                            setOpenDelete(true);
                            handleCloseMenu();
                          }}
                          className="menu-list-items">
                          <div className="menu-list-items-text">Delete</div> <GoTrash style={{ cursor: "pointer" }} />
                        </MenuItem>
                      </Popover>
                    )}
                  </>
                ),
              },
            ]}
            initialState={{
              pagination: {
                rowCount: -1,
              },
            }}
            onSortModelChange={(e) => {
              if (e && e.length > 0) {
                setsortOrder(e[0].sort);
                setColumnName(e[0].field);
              } else {
                setsortOrder("");
                setColumnName("");
              }
            }}
            paginationMode="server"
            sortingMode="server"
            rowCount={rowCount}
            pageSizeOptions={[15, 50, 100]}
            paginationModel={{ pageSize: pageSize, page: page }}
            onPaginationModelChange={(event) => {
              setPage(event?.page);
              setPageSize(event?.pageSize);
            }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>

      {open ? (
        <AddEditRoom
          open={open}
          isAdd={isAdd}
          clickedRoom={clickedRoom}
          handleClose={() => handleClose()}
          companyId={company_id}
          locationId={location_id}
        />
      ) : null}

      {openDelete ? (
        <DeleteRoom
          open={openDelete}
          clickedRoom={clickedRoom}
          handleClose={() => handleClose()}
          getRooms={getRooms}
          status={status}
        />
      ) : null}
    </>
  );
};

export default Room;
