import "./App.css";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import Forgotpass from "./pages/Forgotpass/Forgotpass";
import Main from "./pages/Main/Main";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Company from "./pages/Company/CompanyListAdd/Company";
import CompanyView from "./pages/Company/CompanyView/View";
import Milestone from "./pages/Milestone/MilestoneListAdd/Milestone";
import MilestoneView from "./pages/Milestone/MilestoneView/View";
import Activities from "./pages/Activities/ActivitiesListAdd/Activities";
import ActivitiesView from "./pages/Activities/ActivitiesView/View";
import Room from "./pages/Room/RoomListAdd/Room";
import RoomView from "./pages/Room/RoomView/View";
import ResetPassword from "./pages/Reset-Password/ResetPass";
import Plant from "./pages/Plant/PlantListAdd/Plant";
import PlantView from "./components/Plant/View/View.js";
import Maintenance from "./pages/Maintenance/MaintenanceListAdd/Maintenance";
import MaintenanceView from "./components/Maintainance/View/View.js";
import Role from "./pages/Role/RoleListAdd/Role";
import Location from "./pages/Location/Location";
import Department from "./pages/Department/DepartmentListAdd/Department";
import Schedule from "./pages/Schedule/ScheduleListAdd/Schedule";
import Inventory from "./pages/Inventory/InventoryListAdd/Inventory";
import EmployeeView from "./components/Employee/View/View";
import Employee from "./pages/Employee/Employee.js";
import PrivateRoute from "./components/widgets/privateRoute.js";
import ViewLocation from "./components/Location/View/View.js";
import SecureRoute from "./components/widgets/secureRoutes.js";
import PageNotFound from "./pages/PageNotFound.js";
import DepartmentView from "./components/Department/View/View.js";
import Sops from "./pages/Sops/SopsListAdd/Sops.js";
import SopsView from "./components/Sops/View/View.js";

import { Component, useState } from "react";
import Task from "./pages/Task/task.js";
import TaskView from "./components/Task/View/View.js";
import View from "./components/ViewDetails/View.js";
import InventoryView from "./pages/Inventory/InventoryView/InventoryView.js";
import Profile from "./pages/Profile/Profile.js";
import ScheduleView from "./components/Schedule/View/View.js";
import ViewDetails from "./components/Schedule/ViewDetails/ViewDetails.js";
import ScheduleConflict from "./pages/Conflicted Schedule/ScheduleConflict.js";
import ScheduleList from "./pages/Report/scheduleList.js";
import Report from "./pages/Report/Report.js";
import WorkTask from "./pages/WorkTask/workTask.js";
import WorkTaskView from "./components/WorkTask/View/View.js";

function App() {
  const routes = [
    {
      path: "/company",
      component: <Company />,
      roles: [1],
    },
    {
      path: "/company/view/:id",
      component: <CompanyView />,
      roles: [1],
    },
    {
      path: "/milestone",
      component: <Milestone />,
      roles: [1, 2, 3],
    },
    {
      path: "/milestone/view/:id",
      component: <MilestoneView />,
      roles: [1, 2, 3],
    },
    {
      path: "/activities",
      component: <Activities />,
      roles: [1, 2, 3],
    },
    {
      path: "/activities/view/:id",
      component: <ActivitiesView />,
      roles: [1, 2, 3],
    },
    {
      path: "/room",
      component: <Room />,
      roles: [1, 2, 3],
    },
    {
      path: "/room/view",
      component: <RoomView />,
      roles: [1, 2, 3],
    },
    {
      path: "/room/view/:roomid",
      component: <RoomView />,
      roles: [1, 2, 3],
    },
    {
      path: "/employee",
      component: <Employee />,
      roles: [1, 2, 3],
    },
    {
      path: "/employee/view/:id",
      // component: <View name={"employee"} headerName={"Employee"} />,
      component: <EmployeeView />,
      roles: [1, 2, 3],
    },
    {
      path: "/plant",
      component: <Plant />,
      roles: [1, 2, 3],
    },
    {
      path: "/plant/view/:id",
      component: <PlantView />,
      roles: [1, 2, 3],
    },
    {
      path: "/maintenance",
      component: <Maintenance />,
      roles: [1, 2, 3],
    },
    {
      path: "/maintenance/view/:id",
      component: <MaintenanceView />,
      roles: [1, 2, 3],
    },
    {
      path: "/role",
      component: <Role />,
      roles: [1, 2, 3],
    },
    {
      path: "/location",
      component: <Location />,
      roles: [1, 2],
    },
    {
      path: "/location/view/:id",
      component: <ViewLocation />,
      roles: [1, 2],
    },
    {
      path: "/department",
      component: <Department />,
      roles: [1, 2, 3],
    },
    {
      path: "/department/view/:id",
      component: <DepartmentView />,
      roles: [1, 2, 3],
    },
    {
      path: "/task",
      component: <Task />,
      roles: [1, 2, 3],
    },
    {
      path: "/work-task",
      component: <WorkTask />,
      roles: [1, 2, 3],
    },
    {
      path: "/work-task/view/:id",
      component: <WorkTaskView />,
      roles: [1, 2, 3],
    },
    {
      path: "/task/view/:id",
      component: <TaskView />,
      roles: [1, 2, 3],
    },
    {
      path: "/sops",
      component: <Sops />,
      roles: [1, 2, 3],
    },
    {
      path: "/sops/view/:id",
      component: <SopsView />,
      roles: [1, 2, 3],
    },
    { path: "/schedule", component: <Schedule />, roles: [1, 2, 3] },
    {
      path: "/schedule/view/:id",
      component: <ScheduleView />,
      roles: [1, 2, 3],
    },
    {
      path: "/schedule/task-view/:id",
      component: <ViewDetails />,
      roles: [1, 2, 3],
    },
    { path: "/inventory", component: <Inventory />, roles: [1, 2, 3] },
    {
      path: "/inventory/view",
      component: <InventoryView />,
      roles: [1, 2, 3],
    },
    {
      path: "/profile",
      component: <Profile />,
      roles: [1, 2, 3],
    },
    {
      path: "/schedule_conflict",
      component: <ScheduleConflict />,
      roles: [1, 2, 3],
    },

    { path: "/reports", component: <ScheduleList />, roles: [1, 2, 3] },
    { path: "/reports/:id", component: <Report />, roles: [1, 2, 3] },
  ];
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-pass" element={<Forgotpass />} />
      <Route path="/reset-pass" element={<ResetPassword />} />
      <Route exact path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={<SecureRoute route={route} />} />
          ))}
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
