import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { PiUploadSimpleThin } from "react-icons/pi";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { RxCross1 } from "react-icons/rx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { HttpService } from "../../../service/HttpService";
import { FormControl, MenuItem, Select } from "@mui/material";
import FileInput from "../../FileInput/FileInput";
import { useAuth } from "../../../context/AuthProvider";
import SelectInput from "../../SelectInput/SelectInput";

function AddEditDepartment({
  open,
  handleClose,
  clickeddepartment,
  isAdd,
  fetchDepartment,
  companyId,
  locationId,
}) {
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [apiError, setApiError] = useState("");
  const [milestones, setMilestones] = useState([]);
  const [activities, setActivities] = useState([]);
  const [tasks, setTasks] = useState([]);

  const autoSequenceOrder = async (payload) => {
    return await httpService
      .post("department/check-auto-sequence", payload)
      .then((response) => {
        if (response?.data?.max_sort_order) {
          formik.setFieldValue("sort_order", response?.data?.max_sort_order);
        } else {
          formik.setFieldValue("sort_order", "");
        }
        return response?.data?.max_sort_order;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  const fetchCompanies = () => {
    httpService
      .get("company/get_companies")
      .then((response) => {
        setCompanies(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleChangeLocation = (key, id) => {
    if (key === "company") {
      httpService
        .get(`location/get_location/company/${id}`)
        .then((response) => {
          setLocations(response?.data);
        })
        .catch((error) => {
          console.error("Error fetching location data:", error);
        });
    }
    if (key === "location") {
      getMilestoneByLocation(id);
    }
    if (key === "milestones") {
      getActivityByMilestone(id);
    }
    if (key === "activities") {
      getTaskByActivity(id);
    }
  };

  const matchSequenceOrder = async (payload) => {
    return await httpService
      .post("department/check-sequence", payload)
      .then((response) => {
        setApiError("");
        return response;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setApiError(error?.message);
        return error;
      });
  };

  const getMilestoneByLocation = async (id) => {
    try {
      const response = await httpService.get(
        `milestone/location/get_milestones/${id}`
      );
      if (response.data.length === 0) {
        formik.setFieldValue("milestones", []);
      }
      setMilestones(response.data);
    } catch (error) {
      console.error("Error fetching milestone data:", error);
      throw error; // Re-throw the error to handle it upstream if needed
    }
  };

  const getActivityByMilestone = (id) => {
    return httpService
      .post(`activity/get_activity/milestone`, { milestone_id: id })
      .then((response) => {
        setActivities(response);
        if (response.length === 0) {
          formik.setFieldValue("activities", []);
          setActivities([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  const getTaskByActivity = (id) => {
    return httpService
      .post(`task/get_tasks/activity`, { activity_id: id })
      .then((response) => {
        setTasks(response);
        if (response.length === 0) {
          formik.setFieldValue("tasks", []);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchCompanies();
    if (companyId > 0) {
      formik.setFieldValue("company", companyId);
      handleChangeLocation("company", companyId);
    }
    if (locationId > 0) {
      formik.setFieldValue("location", locationId);
      handleChangeLocation("location", locationId);
    }
  }, [companyId, locationId, showLoginPopup]);

  useEffect(() => {
    if (clickeddepartment !== "") {
      httpService
        .get(`department/get_department/${clickeddepartment}`)
        .then((response) => {
          formik.setValues({
            name: response?.data[0].name,
            description: response?.data[0].description,
            company: response?.data[0].company_id,
            location: response?.data[0].location_id,
            attached: response?.data[0].attachment,
            sort_order: response?.data[0].sort_order,
            version: response?.data[0].version,
            milestones: response?.data[0].milestone_id,
            activities: response?.data[0].activity_id,
            tasks: response?.data[0].task_id,
          });
          if (response) {
            handleChangeLocation("company", response?.data[0]?.company_id);
            handleChangeLocation("location", response?.data[0]?.location_id);
            handleChangeLocation("milestones", response?.data[0]?.milestone_id);
            handleChangeLocation("activities", response?.data[0]?.activity_id);
          }
        });
    } else {
      formik.resetForm();
    }
  }, [fetchDepartment]);

  const formik = useFormik({
    initialValues: {
      name: "",
      company: (companyId > 0 && companyId) || "",
      location: (locationId > 0 && locationId) || "",
      description: "",
      file: [],
      attached: "",
      sort_order: "",
      version: "",
      milestones: [],
      activities: [],
      tasks: [],
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Please Enter Department Name")
        .max(200, "Department Name must be less than 200 characters"),
      company: Yup.number()
        .min(1, "Please Select Company")
        .required("Please Select Company"),
      location: Yup.number()
        .min(1, "Please Select Location")
        .required("Please Select Location"),
      description: Yup.string()
        .trim()
        // .required("Please Enter Description")
        .max(2000, "Description must be less than 2000 characters"),
      file: Yup.mixed()
        // .required("Attachment Is Required")
        .test("fileSize", "Attachment size must be less than 10MB", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          return files.every((file) => file.size <= 10485760); // Check size of each file
        })
        .test("fileFormat", "Invalid file format", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          if (files.length === 0) return true; // Skip if no files provided
          const allowedExtensions = {
            video: ["mp4", "webm", "mov"],
            pdf: ["pdf"],
            document: ["doc", "docx"],
            spreadsheet: ["xls", "xlsx", "csv"],
            image: ["jpg", "png", "jpeg", "gif", "webp"],
          };
          return files.every((file) => {
            if (!file || !file.name) return false; // If file or file name is undefined, return false
            const fileExtension = file?.name?.split(".").pop().toLowerCase();
            const fileType = Object.keys(allowedExtensions).find((type) =>
              allowedExtensions[type].includes(fileExtension)
            );
            return !!fileType;
          });
        }),
    }),

    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("company_id", values.company);
      formData.append("location_id", values?.location ? values?.location : 1);
      formData.append("description", values.description || "");
      formData.append("sort_order", values.sort_order || "");
      formData.append("version", values.version || "");
      if (values.file && values.file.length > 0) {
        for (let i = 0; i < values.file.length; i++) {
          formData.append("attachment", values.file[i]);
        }
      } else {
        formData.append("attachment", []);
      }
      //Runs only for edit purpose
      if (values.attached) {
        if (clickeddepartment !== "" && !isAdd) {
          for (let i = 0; i < values.attached.length; i++) {
            formData.append(`existFile[${i}]`, values.attached[i]);
          }
        }
      }
      if (values.activities?.length > 0) {
        values?.activities?.forEach((item, index) => {
          formData.append(`activity_id[${index}]`, item);
        });
      }
      if (values?.milestones?.length > 0) {
        values?.milestones?.forEach((item, index) => {
          formData.append(`milestone_id[${index}]`, item);
        });
      }
      if (values?.tasks?.length > 0) {
        values?.tasks?.forEach((item, index) => {
          formData.append(`task_id[${index}]`, item);
        });
      }
      if (!apiError) {
        try {
          if (isAdd) {
            await httpService
              .post("department/add_department", formData)
              .then((response) => {
                if (response) {
                  toast.success("Department Added Successfully", {
                    position: "top-right",
                  });
                  formik.resetForm();
                  handleClose();
                  fetchDepartment();
                }
              });
          } else {
            await httpService
              .put(
                `${"department/edit_department"}/${clickeddepartment}`,
                formData
              )
              .then((response) => {
                if (response) {
                  toast.success("Department Updated Successfully", {
                    position: "top-right",
                  });
                  formik.resetForm();
                  handleClose();
                  fetchDepartment();
                }
              });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    if (
      formik.values?.sort_order === "" &&
      companyId > 0 &&
      locationId > 0 &&
      clickeddepartment === ""
    ) {
      autoSequenceOrder(
        { company_id: companyId, location_id: locationId },
        formik.setFieldValue
      );
    }
  }, [companyId, locationId, formik.values.sort_order]);

  const handleCloseSave = () => {
    formik.resetForm();
    handleClose();
    setLocations([]);
  };

  return (
    <>
      <Modal
        className="department-modal"
        open={open}
        onClose={() => {
          handleCloseSave();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <div className="modal_block">
            <div className="modal_header">
              <div className="modal_title_wrapper">
                <h2 className="modal_title">
                  {isAdd === true ? "Add Department" : "Edit Department"}
                </h2>
              </div>
              <div className="cancel-btn-wrap">
                <RxCross1
                  onClick={() => {
                    handleCloseSave();
                  }}
                />
              </div>
            </div>
            <div className="modal_content">
              <div className="row">
                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>
                      Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      id="name"
                      InputProps={{}}
                      placeholder="Enter Your Department Name"
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div style={{ color: "red" }}>{formik.errors.name}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <SelectInput
                    label="Company"
                    name="company_id"
                    value={formik.values.company}
                    options={companies}
                    multiple={false}
                    onChange={(event) => {
                      formik.setFieldValue("company", event.target.value);
                      handleChangeLocation("company", event.target.value);
                      formik.setFieldValue("location", "");
                    }}
                    onBlur={(e) => formik.handleBlur(e)}
                    error={formik.touched.company && formik.errors.company}
                    required={true}
                    displayEmpty
                    disabled={companyId > 0 ? true : false}
                  />
                </div>
                <div className="col-md-6">
                  <SelectInput
                    label="Location"
                    name="location_id"
                    value={formik.values.location}
                    options={locations}
                    multiple={false}
                    onChange={(event) => {
                      formik.setFieldValue("location", event.target.value);
                      handleChangeLocation("location", event.target.value);
                      if (
                        formik.values?.company &&
                        formik.values?.sort_order &&
                        event.target.value
                      ) {
                        const payload = {
                          company_id: formik.values?.company,
                          location_id: event.target.value,
                          sort_order: formik.values?.sort_order,
                          id: clickeddepartment,
                        };
                        matchSequenceOrder(payload);
                      } else {
                        setApiError("");
                      }
                      const payload = {
                        company_id: formik.values?.company,
                        location_id: event.target.value,
                      };
                      if (clickeddepartment === "") {
                        autoSequenceOrder(payload);
                      }
                    }}
                    onBlur={(e) => formik.handleBlur(e)}
                    error={formik.touched.location && formik.errors.location}
                    required={true}
                    displayEmpty
                    disabled={locationId > 0 ? true : false}
                  />
                </div>
                <div className="col-md-6">
                  <SelectInput
                    label="Milestone"
                    name="milestones"
                    value={formik.values.milestones || []}
                    options={milestones}
                    selectAll={true}
                    multiple={true}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value[value.length - 1] === "all") {
                        const selectedIds =
                          formik.values.milestones?.length === milestones.length
                            ? []
                            : milestones.map((item) => item.id);
                        formik.setFieldValue("milestones", selectedIds);
                        handleChangeLocation("milestones", selectedIds);
                        return;
                      }
                      formik.setFieldValue("milestones", value);
                      handleChangeLocation("milestones", value);
                    }}
                    onBlur={(e) => formik.handleBlur(e)}
                    error={
                      formik.touched.milestones && formik.errors.milestones
                    }
                    required={false}
                    displayEmpty
                    disabled={companyId > 0 ? true : false}
                  />
                </div>

                <div className="col-md-6">
                  <SelectInput
                    label="Activities"
                    name="activities"
                    value={formik.values.activities || []}
                    options={activities}
                    selectAll={true}
                    multiple={true}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value[value.length - 1] === "all") {
                        const selectedIds =
                          formik.values.activities?.length === activities.length
                            ? []
                            : activities.map((item) => item.id);
                        formik.setFieldValue("activities", selectedIds);
                        handleChangeLocation("activities", selectedIds);
                        return;
                      }
                      formik.setFieldValue("activities", value);
                      handleChangeLocation("activities", value);
                    }}
                    onBlur={(e) => formik.handleBlur(e)}
                    error={
                      formik.touched.activities && formik.errors.activities
                    }
                    required={false}
                    displayEmpty
                    disabled={companyId > 0 ? true : false}
                  />
                </div>

                <div className="col-md-6">
                  <SelectInput
                    label="Task"
                    name="tasks"
                    value={formik.values.tasks || []}
                    options={tasks}
                    selectAll={true}
                    multiple={true}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value[value.length - 1] === "all") {
                        const selectedIds =
                          formik.values.tasks?.length === tasks.length
                            ? []
                            : tasks.map((item) => item.id);
                        formik.setFieldValue("tasks", selectedIds);
                        handleChangeLocation("tasks", selectedIds);
                        return;
                      }
                      formik.setFieldValue("tasks", value);
                      handleChangeLocation("tasks", value);
                    }}
                    onBlur={(e) => formik.handleBlur(e)}
                    error={formik.touched.tasks && formik.errors.tasks}
                    required={false}
                    displayEmpty
                    disabled={companyId > 0 ? true : false}
                  />
                </div>

                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>Sequence(#)</label>
                    <TextField
                      name="sort_order"
                      // className={classes.textField}
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      placeholder="Enter Sequence Number"
                      value={formik.values.sort_order}
                      onChange={(event) => {
                        formik.setFieldValue("sort_order", event.target.value);
                        if (
                          formik.values?.company &&
                          formik.values?.location &&
                          event.target.value
                        ) {
                          const payload = {
                            company_id: formik.values?.company,
                            location_id: formik.values?.location,
                            sort_order: event.target.value,
                            id: clickeddepartment,
                          };
                          matchSequenceOrder(payload);
                        } else {
                          setApiError("");
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {apiError && (
                      <span style={{ color: "red" }}>{apiError}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>Reff Doc. Version(s)</label>
                    <TextField
                      id="input-version"
                      name="version"
                      type="text"
                      InputProps={{ inputProps: { min: 0 } }}
                      placeholder="Enter Version"
                      value={formik.values.version}
                      onChange={async (e) => {
                        formik.setFieldValue("version", e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.version && formik.touched.version && (
                      <span style={{ color: "red" }}>
                        {formik.errors.version}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form_field_wrapper">
                    <label>Description</label>
                    <div>
                      <TextareaAutosize
                        id="description"
                        className="w-100 text-area"
                        aria-label="Type Description"
                        minRows={3}
                        placeholder="Type Description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                      />
                      {formik.touched.description &&
                      formik.errors.description ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <FileInput
                  file={formik?.values?.file}
                  attachment={formik?.values?.attached}
                  handleBlur={formik.handleBlur}
                  name="file"
                  name2="attached"
                  setFieldValue={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                />
                {formik.errors.file && formik.touched.file && (
                  <span style={{ color: "red" }}>{formik.errors.file}</span>
                )}
              </div>
            </div>
            <div className="modal_footer">
              <button
                onClick={() => {
                  handleCloseSave();
                }}
                className="btn btn-border"
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                {isAdd === true ? "Save" : "Update"}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default AddEditDepartment;
