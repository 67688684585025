import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import moment from "moment";

const handleDownloadSopsExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchSopsData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`sops/get_sops`)
        .then((response) => {
          if (response?.data) {
            const sopsData = response?.data?.data;
            resolve(sopsData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching SOPs data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const sopsData = await fetchSopsData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("SOPs");

    // Add headers
    worksheet.addRow([
      "SOPs Name",
      "Activity",
      "Milestone",
      "Company",
      "Location",
      "Maintenance",
      "Task",
    ]);
    worksheet.getRow(1).alignment = { horizontal: "center" }; // Center align the text

    if (sopsData !== null && sopsData !== undefined && sopsData.length > 0) {
      sopsData.forEach((sop) => {
        worksheet.addRow([
          sop?.name || "",
          sop?.activities?.length
            ? sop?.activities.map((activity) => activity.name || "").join(", ")
            : "",
          sop?.milestones?.length
            ? sop?.milestones
                .map((milestone) => milestone.name || "")
                .join(", ")
            : "",
          sop?.company_name || "",
          sop?.locations?.length
            ? sop?.locations.map((location) => location.name || "").join(", ")
            : "",
          sop?.maintenance_name || "",
          sop?.tasks?.length
            ? sop?.tasks.map((task) => task.name || "").join(", ")
            : "",
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `SopsData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadSopsExcel };
