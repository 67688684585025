import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { RxCross1 } from "react-icons/rx";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { HttpService } from "../../../service/HttpService";
import FileInput from "../../FileInput/FileInput";
import { useAuth } from "../../../context/AuthProvider";
import { splitDuration } from "../../../Mixin/DisplayDuration";
import CommonDropdown from "../../SelectInput/CommonDropdown";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

function AddEditMaintainence({
  open,
  handleClose,
  clickedmaintenance,
  isAdd,
  fetchMaintenance,
  companyId,
  locationId,
}) {
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const [milestoneNames, setMilestoneNames] = useState([]);
  const [activityNames, setActivityNames] = useState([]);
  const [employeeNames, setEmployeeNames] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [showOngoingField, setshowOngoingField] = useState(true);
  const [apiError, setApiError] = useState("");
  const [department, setdepartment] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [locationIds, setLocationIds] = useState("");
  const httpService = new HttpService(setShowLoginPopup, locationIds);
  const [startDate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [isMonthly, setIsMonthly] = useState(false);
  const [isYearly, setIsYearly] = useState(false);

  const autoSequenceOrder = async (payload) => {
    return await httpService
      .post("maintenance/check-auto-sequence", payload)
      .then((response) => {
        if (response?.data?.max_sort_order) {
          formik.setFieldValue("sort_order", response?.data?.max_sort_order);
        } else {
          formik.setFieldValue("sort_order", "");
        }
        return response?.data?.max_sort_order;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  const fetchDepartment = () => {
    httpService
      .get("department/get_department")
      .then((response) => {
        setdepartment(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  const handleCheckboxChange = (event) => {
    setshowOngoingField((showOngoingField) => !showOngoingField);
    if (event.target.checked) {
      fetchDepartment();
    } else if (event.target.checked === false) {
      formik.setFieldValue("department", []);
      formik.setFieldValue("employee", []);
      setEmployeeNames([]);
      formik.setFieldValue("sunday_working_status", 1);
      formik.setFieldValue("monday_working_status", 1);
      formik.setFieldValue("tuesday_working_status", 1);
      formik.setFieldValue("wednesday_working_status", 1);
      formik.setFieldValue("thursday_working_status", 1);
      formik.setFieldValue("friday_working_status", 1);
      formik.setFieldValue("saturday_working_status", 1);
    }
  };

  const fetchCompanies = () => {
    httpService
      .get("company/get_companies")
      .then((response) => {
        setCompanies(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  const fetchRooms = () => {
    httpService
      .get("rooms")
      .then((res) => {
        setRooms(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchCompanies();
    if (companyId > 0) {
      getLocationByCompany(companyId);
    }
    if (locationId > 0) {
      getMilestoneByLocation(locationId);
    }
  }, [companyId, locationId, showLoginPopup]);

  const handleDropdownChanges = (key, id) => {
    if (key === "company") {
      // Call the API to fetch location data based on the selected company_id
      getLocationByCompany(id);
      formik.setFieldValue("location", "");
      formik.setFieldValue("milestone", []);
      formik.setFieldValue("activity", []);
      setLocations([]);
      setMilestoneNames([]);
      setActivityNames([]);
    }

    if (key === "location") {
      setLocationIds(id);
      setActivityNames([]);
      getMilestoneByLocation(id);
      formik.setFieldValue("milestone", []);
      formik.setFieldValue("activity", []);
      setMilestoneNames([]);
    }

    if (key === "milestone") {
      getActivityByMilestone(id);
    }

    if (key === "department") {
      getEmployeeByDepartment(id);
    }
  };

  useEffect(() => {
    if (locationIds) {
      fetchRooms();
      // fetchPlants();
    }
    fetchDepartment();
  }, [locationIds]);

  const fetchEditMaintainanceData = () => {
    httpService
      .get(`maintenance/get_maintenance/${clickedmaintenance}`)
      .then((response) => {
        if (response.success) {
          console.log(
            "gfds : ",
            dayjs(response?.data[0]?.start_date).format("MM/DD/YYYY") || ""
          );

          const { days, hours, minutes } =
            splitDuration(
              response?.data[0]?.days,
              response?.data[0]?.hours,
              response?.data[0]?.minutes
            ) || {};

          if (response?.data[0]?.date_range_type === "monthly") {
            setIsMonthly(true);
          } else if (response?.data[0]?.date_range_type === "yearly") {
            setIsYearly(true);
          }
          formik.setValues({
            name: response?.data[0]?.name,
            durationDays: days || 0,
            durationHours: hours || 0,
            durationMinute: minutes || 0,
            company: response?.data[0]?.company_id,
            location: response?.data[0]?.location_id,
            activity:
              (response?.data[0]?.activities?.length > 0 &&
                response?.data[0]?.activities.map((item) => item.id)) ||
              [],
            milestone:
              (response?.data[0]?.milestones?.length > 0 &&
                response?.data[0]?.milestones.map((item) => item.id)) ||
              [],
            room_id:
              (response?.data[0]?.rooms?.length > 0 &&
                response?.data[0]?.rooms.map((item) => item.id)) ||
              [],
            description: response?.data[0]?.description,
            attached: response?.data[0]?.attachment || [],
            sort_order: response?.data[0]?.sort_order || "",
            version: response?.data[0]?.version || "",
            sunday_working_status: response?.data[0]?.sunday_working_status,
            monday_working_status: response?.data[0]?.monday_working_status,
            tuesday_working_status: response?.data[0]?.tuesday_working_status,
            wednesday_working_status:
              response?.data[0]?.wednesday_working_status,
            thursday_working_status: response?.data[0]?.thursday_working_status,
            friday_working_status: response?.data[0]?.friday_working_status,
            saturday_working_status: response?.data[0]?.saturday_working_status,
            department_id:
              (response?.data[0]?.departments?.length > 0 &&
                response?.data[0]?.departments.map((item) => item.id)) ||
              [],
            employee:
              (response?.data[0]?.employees?.length > 0 &&
                response?.data[0]?.employees.map((item) => item.id)) ||
              [],
            date_range_type: response?.data[0]?.date_range_type,
            start_date:
              dayjs(response?.data[0]?.start_date).format("MM/DD/YYYY") || "",
            end_date:
              dayjs(response?.data[0]?.end_date).format("MM/DD/YYYY") || "",
          });
          if (response) {
            if (
              response?.data[0]?.departments &&
              response?.data[0]?.departments.length > 0
            ) {
              handleCheckboxChange({ target: { checked: true } });
              formik.setFieldValue(
                "department",
                (response?.data[0]?.departments?.length > 0 &&
                  response?.data[0]?.departments.map((item) => item.id)) ||
                  []
              );
            }
            if (response?.data[0]?.departments?.length > 0) {
              getEmployeeByDepartment(
                response?.data[0]?.departments?.map((item) => item.id)
              );
            }
            if (response?.data[0]?.milestones?.length > 0) {
              getActivityByMilestone(
                response?.data[0]?.milestones?.map((item) => item.id)
              );
            }
            if (response?.data[0]?.company_id) {
              getLocationByCompany(response?.data[0]?.company_id);
            }
            if (response?.data[0]?.location_id) {
              getMilestoneByLocation(response?.data[0]?.location_id);
            }
          }
          setLocationIds(response?.data[0]?.location_id);
        }
      });
  };

  useEffect(() => {
    if (clickedmaintenance !== "") {
      fetchEditMaintainanceData();
    } else {
      formik.resetForm();
    }
  }, [clickedmaintenance]);

  const getLocationByCompany = (id) => {
    return httpService
      .get(`location/get_location/company/${id}`)
      .then((response) => {
        setLocations(response?.data);
        if (response?.data.length === 0) {
          formik.setFieldValue("location", "");
        }
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
        return error;
      });
  };

  const getMilestoneByLocation = (id) => {
    return httpService
      .get(`milestone/location/get_milestones/${id}`)
      .then((response) => {
        if (response.data.length === 0) {
          formik.setFieldValue("milestone", "");
        }
        setMilestoneNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching milestone data:", error);
        throw error; // Re-throw the error to handle it upstream if needed
      });
  };

  const getActivityByMilestone = (id) => {
    return httpService
      .post(`activity/get_activity/milestone`, { milestone_id: id })
      .then((response) => {
        setActivityNames(response);
        if (response.length === 0) {
          formik.setFieldValue("activity", []);
          setActivityNames([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  const getEmployeeByDepartment = (id) => {
    return httpService
      .post(`employee/get_employees/department`, { department_id: id })
      .then((response) => {
        setEmployeeNames(response?.data);
        if (response.length === 0) {
          formik.setFieldValue("employee", []);
          setEmployeeNames([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const matchSequenceOrder = async (payload) => {
    return await httpService
      .post("maintenance/check-sequence", payload)
      .then((response) => {
        setApiError("");
        return response;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setApiError(error?.message);
        return error;
      });
  };

  const workingStatus = [
    {
      status: `sunday_working_status`,
      label: "Sun",
    },
    {
      status: `monday_working_status`,
      label: "Mon",
    },
    {
      status: `tuesday_working_status`,
      label: "Tue",
    },
    {
      status: `wednesday_working_status`,
      label: "Wed",
    },
    {
      status: `thursday_working_status`,
      label: "Thu",
    },
    {
      status: `friday_working_status`,
      label: "Fri",
    },
    {
      status: `saturday_working_status`,
      label: "Sat",
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      durationDays: "",
      durationHours: "",
      durationMinute: "",
      activity: [],
      milestone: [],
      company: (companyId > 0 && companyId) || "",
      location: (locationId > 0 && locationId) || "",
      employee: [],
      department: [],
      description: "",
      file: [],
      attached: "",
      sort_order: "",
      version: "",
      sunday_working_status: 1,
      monday_working_status: 1,
      tuesday_working_status: 1,
      wednesday_working_status: 1,
      thursday_working_status: 1,
      friday_working_status: 1,
      saturday_working_status: 1,
      room_id: [],
      date_range_type: "",
      start_date: null,
      end_date: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Please Enter Work Tasks Name")
        .max(200, "Work Tasks Name must be less than 200 characters"),
      company: Yup.string().required("Please Select Company"),
      location: Yup.string().required("Please Select Location"),
      durationDays: Yup.number().nullable(),
      durationHours: Yup.number().nullable().max(24, "Maximum 24 hours"),
      durationMinute: Yup.number().nullable().max(60, "Maximum 60 minutes"),
      description: Yup.string().max(
        2000,
        "Description must be less than 2000 characters"
      ),
      file: Yup.mixed()
        // .required("Attachment Is Required")
        .test("fileSize", "Attachment size must be less than 10MB", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          return files.every((file) => file.size <= 10485760); // Check size of each file
        })
        .test("fileFormat", "Invalid file format", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          if (files.length === 0) return true; // Skip if no files provided
          const allowedExtensions = {
            video: ["mp4", "webm", "mov"],
            pdf: ["pdf"],
            document: ["doc", "docx"],
            spreadsheet: ["xls", "xlsx", "csv"],
            image: ["jpg", "png", "jpeg", "gif", "webp"],
          };
          return files.every((file) => {
            if (!file || !file.name) return false; // If file or file name is undefined, return false
            const fileExtension = file?.name?.split(".").pop().toLowerCase();
            const fileType = Object.keys(allowedExtensions).find((type) =>
              allowedExtensions[type].includes(fileExtension)
            );
            return !!fileType;
          });
        }),
    }).test("at-least-one", null, (values) => {
      const { durationDays, durationHours, durationMinute } = values;
      if (!durationDays && !durationHours && !durationMinute) {
        return new Yup.ValidationError(
          "Please Enter One Duration",
          null,
          "durationDays"
        );
      }
      if (durationDays < 0 || durationHours < 0 || durationMinute < 0) {
        return new Yup.ValidationError(
          "Please Enter Valid Duration",
          null,
          "durationDays"
        );
      }
      return true;
    }),
    onSubmit: async (values) => {
      if (!apiError) {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("days", values.durationDays || 0);
        formData.append("hours", values.durationHours || 0);
        formData.append("minutes", values.durationMinute || 0);
        if (values.activity?.length > 0) {
          values?.activity?.forEach((item, index) => {
            formData.append(`activity_id[${index}]`, item);
          });
        }
        if (values?.milestone.length > 0) {
          values?.milestone?.forEach((item, index) => {
            formData.append(`milestone_id[${index}]`, item);
          });
        }
        formData.append("company_id", values.company || "");
        formData.append("location_id", values.location || "");
        formData.append("description", values.description || "");
        formData.append("sort_order", values.sort_order || "");
        formData.append("version", values.version || "");
        formData.append("date_range_type", values.date_range_type || "");
        formData.append("start_date", values.start_date || "");
        formData.append("end_date", values.end_date || "");
        if (values?.room_id.length > 0) {
          values?.room_id?.forEach((item, index) => {
            formData.append(`room_id[${index}]`, item || "");
          });
        }
        if (values.employee && values.employee.length > 0) {
          formData.append(
            "sunday_working_status",
            values.sunday_working_status || 0
          );
          formData.append(
            "monday_working_status",
            values.monday_working_status || 0
          );
          formData.append(
            "tuesday_working_status",
            values.tuesday_working_status || 0
          );
          formData.append(
            "wednesday_working_status",
            values.wednesday_working_status || 0
          );
          formData.append(
            "thursday_working_status",
            values.thursday_working_status || 0
          );
          formData.append(
            "friday_working_status",
            values.friday_working_status || 0
          );
          formData.append(
            "saturday_working_status",
            values.saturday_working_status || 0
          );
        }

        if (values.department && values.department.length > 0) {
          values?.department?.forEach((item, index) => {
            formData.append(`department_id[${index}]`, item);
          });
        }
        if (values.employee && values.employee.length > 0) {
          values?.employee?.forEach((item, index) => {
            formData.append(`employee_id[${index}]`, item);
          });
        }
        if (values.file && values.file.length > 0) {
          for (let i = 0; i < values.file.length; i++) {
            formData.append("attachment", values.file[i]);
          }
        } else {
          formData.append("attachment", []);
        }
        if (values.attached) {
          if (clickedmaintenance !== "" && !isAdd) {
            for (let i = 0; i < values.attached.length; i++) {
              formData.append(`existFile[${i}]`, values.attached[i]);
            }
          }
        }
        try {
          if (isAdd) {
            const response = await httpService.post(
              "maintenance/add_maintenance",
              formData
            );
            if (response) {
              toast.success("Work Tasks Added Successfully", {
                position: "top-right",
              });
              formik.resetForm();
              handleClose();
              fetchMaintenance();
            }
          } else {
            const res = await httpService.put(
              `${"maintenance/edit_maintenance"}/${clickedmaintenance}`,
              formData
            );
            if (res) {
              toast.success("Work Tasks Updated Successfully", {
                position: "top-right",
              });
              handleClose();
              fetchMaintenance();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    if (
      formik?.values?.sort_order === "" &&
      companyId > 0 &&
      locationId > 0 &&
      clickedmaintenance === ""
    ) {
      autoSequenceOrder(
        { company_id: companyId, location_id: locationId },
        formik.setFieldValue
      );
    }
  }, [companyId, locationId, formik.values.sort_order]);

  const handleCloseSave = () => {
    formik.resetForm();
    handleClose();
    setLocations([]);
    setActivityNames([]);
  };

  const handleMonthlyChange = () => {
    setIsMonthly(!isMonthly);
    formik.setFieldValue("date_range_type", "monthly");
    setIsYearly(false);
  };

  const handleYearlyChange = () => {
    setIsYearly(!isYearly);
    formik.setFieldValue("date_range_type", "yearly");
    setIsMonthly(false);
  };
  const currentDate = dayjs();

  return (
    <Modal
      className="plant-modal"
      open={open}
      onClose={() => {
        handleCloseSave();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="modal_block">
          <div className="modal_header">
            <div className="modal_title_wrapper">
              <h2 className="modal_title">
                {isAdd === true ? "Add Work Tasks" : "Edit Work Tasks"}
              </h2>
            </div>
            <div className="cancel-btn-wrap">
              <RxCross1
                onClick={() => {
                  handleCloseSave();
                }}
              />
            </div>
          </div>
          <div className="modal_content">
            <div className="row">
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label>
                    Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="name"
                    InputProps={{}}
                    placeholder="Enter Your Work Tasks Name"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: "red" }}>{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form_field_wrapper">
                  <label>
                    Duration<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    type="number"
                    id="durationDays"
                    InputProps={{ inputProps: { min: 0 } }}
                    placeholder="DD"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.durationDays}
                  />
                  {formik.touched.durationDays && formik.errors.durationDays ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.durationDays}
                    </div>
                  ) : null}
                  {formik.touched.durationHours &&
                  formik.errors.durationHours ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.durationHours}
                    </div>
                  ) : null}
                  {formik.touched.durationMinute &&
                  formik.errors.durationMinute ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.durationMinute}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form_field_wrapper">
                  <label>&nbsp;</label>
                  <TextField
                    type="number"
                    id="durationHours"
                    InputProps={{ inputProps: { min: 0 } }}
                    placeholder="HH"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.durationHours}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form_field_wrapper">
                  <label>&nbsp;</label>
                  <TextField
                    type="number"
                    id="durationMinute"
                    InputProps={{ inputProps: { min: 0 } }}
                    placeholder="MM"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.durationMinute}
                  />
                </div>
              </div>

              <CommonDropdown
                companyId={companyId}
                locationId={locationId}
                valuesCompany={formik.values?.company}
                valuesLocation={formik.values?.location}
                valuesActivity={formik.values?.activity}
                valuesMilestone={formik.values?.milestone}
                com_id={"company"}
                loc_id={"location"}
                act_id={"activity"}
                mil_id={"milestone"}
                sort_order={formik.values?.sort_order}
                handleBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
                companies={companies}
                locations={locations}
                milestones={milestoneNames}
                activities={activityNames}
                errorCompany={formik.touched.company && formik.errors.company}
                errorLocation={
                  formik.touched.location && formik.errors.location
                }
                errorMilestone={""}
                errorActivity={""}
                handleChangeLocation={handleDropdownChanges}
                rows={clickedmaintenance}
                setApiError={setApiError}
                matchSequenceOrder={matchSequenceOrder}
                autoSequenceOrder={autoSequenceOrder}
                valuesEmployee={[1]}
              />
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label>Room Name</label>
                  <div className="fieldwithradio">
                    <FormControl key={"room"} fullWidth>
                      <Select
                        id="demo-simple-select-room"
                        name="room_id"
                        value={formik.values.room_id}
                        multiple
                        onChange={(event) => {
                          const values = event?.target?.value;
                          if (values[values.length - 1] === "all") {
                            const selectedIds =
                              formik.values.room_id?.length === rooms.length
                                ? []
                                : rooms.map((item) => item.id);
                            formik.setFieldValue("room_id", selectedIds);
                            return;
                          }
                          formik.setFieldValue("room_id", values);
                        }}
                        onBlur={formik.handleBlur}
                        displayEmpty
                        MenuProps={{
                          getcontentanchorel: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          keepMounted: true,
                        }}
                        renderValue={(selected) => {
                          if (selected?.length === 0) {
                            return <span>Select Rooms</span>;
                          }
                          const selectedData =
                            selected &&
                            selected?.map(
                              (id) =>
                                rooms.find((item) => item.id === id)
                                  ?.prefix_name ||
                                rooms.find((item) => item.id === id)?.name
                            );

                          return selectedData?.join(", ");
                        }}
                      >
                        <MenuItem value="" selected disabled>
                          Select Room
                        </MenuItem>
                        {rooms && rooms?.length > 0 && (
                          <MenuItem value="all">
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  formik.values?.room_id?.length ===
                                  rooms?.length
                                }
                                indeterminate={
                                  formik.values?.room_id?.length > 0 &&
                                  formik.values?.room_id?.length < rooms?.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {rooms?.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            <Checkbox
                              checked={
                                formik.values?.room_id?.length > 0 &&
                                formik.values?.room_id?.includes(item.id)
                              }
                            />
                            <ListItemText
                              primary={item.prefix_name || item.name}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {formik.touched.room_id && formik.errors.room_id ? (
                    <div style={{ color: "red" }}>{formik.errors.room_id}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label>Sequence(#)</label>
                  <TextField
                    name="sort_order"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    placeholder="Enter Sequence Number"
                    value={formik.values.sort_order}
                    onChange={(event) => {
                      formik.setFieldValue("sort_order", event.target.value);
                      if (
                        formik.values?.company &&
                        formik.values?.location &&
                        event.target.value
                      ) {
                        const payload = {
                          company_id: formik.values?.company,
                          location_id: formik.values?.location,
                          sort_order: event.target.value,
                          id: clickedmaintenance,
                        };
                        matchSequenceOrder(payload);
                      } else {
                        setApiError("");
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {apiError && <span style={{ color: "red" }}>{apiError}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label>Reff Doc. Version(s)</label>
                  <TextField
                    id="input-version"
                    name="version"
                    type="text"
                    InputProps={{ inputProps: { min: 0 } }}
                    placeholder="Enter Version"
                    value={formik.values.version}
                    onChange={async (e) => {
                      formik.setFieldValue("version", e.target.value);
                    }}
                    onBlur={formik?.handleBlur}
                  />
                  {formik.errors.version && formik.touched.version && (
                    <span style={{ color: "red" }}>
                      {formik.errors.version}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="col-md-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isMonthly}
                        onChange={handleMonthlyChange}
                      />
                    }
                    label="Monthly"
                  />
                </div>
                <div className="col-md-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isYearly}
                        onChange={handleYearlyChange}
                      />
                    }
                    label="Yearly"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>Start Date</label>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(formik.values.start_date)}
                          onChange={(date) =>
                            formik.setFieldValue(
                              "start_date",
                              dayjs(date.$d).format("MM/DD/YYYY")
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                          minDate={currentDate}
                          format="MM/DD/YYYY"
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>End Date</label>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(formik.values.end_date)}
                          onChange={(date) =>
                            formik.setFieldValue(
                              "end_date",
                              dayjs(date.$d).format("MM/DD/YYYY")
                            )
                          }
                          renderInput={(params) => <TextField {...params} />}
                          format="MM/DD/YYYY"
                          minDate={startDate || currentDate}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form_field_wrapper">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showOngoingField}
                          onChange={(e) => {
                            handleCheckboxChange(e);
                          }}
                        />
                      }
                      label="Ongoing"
                    />
                  </FormGroup>
                </div>
              </div>
              {showOngoingField && (
                <>
                  <div className="col-md-4">
                    <div className="form_field_wrapper">
                      <label>Select Department</label>
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          name="department"
                          displayEmpty
                          multiple
                          value={formik.values.department || []}
                          onChange={(event) => {
                            const value = event.target.value;
                            const dept = department.filter((item) =>
                              value.includes(parseInt(item.id))
                            );
                            if (formik.values?.employee?.length > 0) {
                              const employee = employeeNames.filter((item) =>
                                formik.values?.employee.includes(
                                  parseInt(item.id)
                                )
                              );
                              const filteredEmployee = employee?.filter(
                                (employee) =>
                                  dept
                                    .map((department) =>
                                      parseInt(department.id)
                                    )
                                    .includes(employee.department_id)
                              );
                              const selectedEmployee = filteredEmployee?.map(
                                (item) => parseInt(item.id)
                              );
                              formik.setFieldValue(
                                "employee",
                                selectedEmployee
                              );
                            }
                            if (value[value.length - 1] === "all") {
                              const selectedIds =
                                formik.values?.department?.length ===
                                department.length
                                  ? []
                                  : department.map((item) => item.id);
                              if (selectedIds?.length === 0) {
                                formik.setFieldValue("employee", selectedIds);
                              }
                              handleDropdownChanges("department", selectedIds);
                              formik.setFieldValue("department", selectedIds);
                              return;
                            }
                            formik.setFieldValue(
                              "department",
                              event.target.value
                            );
                            handleDropdownChanges(
                              "department",
                              event.target.value
                            );
                          }}
                          onBlur={formik.handleBlur}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <span>Select department</span>;
                            }
                            const selectedData =
                              selected &&
                              selected.map(
                                (id) =>
                                  department.find(
                                    (item) => parseInt(item.id) === parseInt(id)
                                  )?.name
                              );
                            return selectedData.join(", ");
                          }}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            keepMounted: true,
                          }}
                        >
                          <MenuItem value="" selected disabled>
                            Select Department
                          </MenuItem>
                          {department?.length > 0 && (
                            <MenuItem value="all">
                              <ListItemIcon>
                                <Checkbox
                                  checked={
                                    formik.values?.department?.length ===
                                    department?.length
                                  }
                                  indeterminate={
                                    formik.values?.department?.length > 0 &&
                                    formik.values?.department?.length <
                                      department?.length
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText primary="Select All" />
                            </MenuItem>
                          )}
                          {department?.length > 0 &&
                            department?.map((item, index) => (
                              <MenuItem key={index} value={parseInt(item.id)}>
                                <Checkbox
                                  key={item + index}
                                  checked={
                                    formik.values?.department?.length > 0 &&
                                    formik.values?.department?.includes(
                                      parseInt(item.id)
                                    )
                                  }
                                />
                                <ListItemText primary={item.name} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form_field_wrapper">
                      <label>Select Employees</label>
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          name="employee"
                          displayEmpty
                          multiple
                          value={formik.values.employee || []}
                          onChange={(event) => {
                            const value = event.target.value;
                            if (value[value.length - 1] === "all") {
                              const selectedIds =
                                formik.values?.employee?.length ===
                                employeeNames.length
                                  ? []
                                  : employeeNames.map((item) =>
                                      parseInt(item.id)
                                    );
                              formik.setFieldValue("employee", selectedIds);
                              return;
                            }
                            formik.setFieldValue(
                              "employee",
                              event.target.value
                            );
                          }}
                          onBlur={formik.handleBlur}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <span>Select Employee</span>;
                            }
                            const selectedData =
                              selected.length > 0 &&
                              selected?.map(
                                (id) =>
                                  employeeNames?.find(
                                    (employee) =>
                                      parseInt(employee.id) === parseInt(id)
                                  )?.employee_name
                              );
                            return selectedData.join(", ");
                          }}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            keepMounted: true,
                          }}
                        >
                          <MenuItem value="" selected disabled>
                            Select Employee
                          </MenuItem>
                          {employeeNames?.length > 0 && (
                            <MenuItem value="all">
                              <ListItemIcon>
                                <Checkbox
                                  checked={
                                    formik.values?.employee?.length ===
                                    employeeNames?.length
                                  }
                                  indeterminate={
                                    formik.values?.employee?.length > 0 &&
                                    formik.values?.employee?.length <
                                      employeeNames?.length
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText primary="Select All" />
                            </MenuItem>
                          )}
                          {employeeNames?.length > 0 &&
                            employeeNames?.map((item, index) => (
                              <MenuItem key={index} value={parseInt(item.id)}>
                                <Checkbox
                                  key={item + index}
                                  checked={
                                    formik.values?.employee?.length > 0 &&
                                    formik.values?.employee?.includes(
                                      parseInt(item.id)
                                    )
                                  }
                                />
                                <ListItemText primary={item.employee_name} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </>
              )}

              {formik.values?.employee &&
                formik.values?.employee?.length > 0 && (
                  <div className="days-arrenge d-flex flex-wrap flex-md-nowrap">
                    {workingStatus?.map((item, index) => {
                      return (
                        <div key={index} className="d-flex">
                          <div className="form_field_wrapper">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={item.status}
                                    defaultChecked={
                                      formik.values?.[item.status] === 1
                                    }
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        item.status,
                                        e.target.checked === true ? 1 : 0
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                  />
                                }
                              />
                            </FormGroup>
                            {formik.errors?.[item.status] &&
                              formik.touched?.[item.status] && (
                                <span style={{ color: "red" }}>
                                  {formik.errors?.[item.status]}
                                </span>
                              )}
                          </div>
                          <div className="form_field_wrapper">
                            <TextField
                              defaultValue={item?.label}
                              variant="outlined"
                              InputProps={{ readOnly: true }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              <div className="col-md-12">
                <div className="form_field_wrapper">
                  <label>Description</label>
                  <div>
                    <TextareaAutosize
                      id="description"
                      className="w-100 text-area"
                      aria-label="Type Description"
                      minRows={3}
                      placeholder="Type Description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <FileInput
                file={formik.values.file}
                attachment={formik.values.attached}
                handleBlur={formik.handleBlur}
                name="file"
                name2="attached"
                setFieldValue={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
              />
              {formik.errors.file && formik.touched.file && (
                <span style={{ color: "red" }}>{formik.errors.file}</span>
              )}
            </div>
          </div>
          <div className="modal_footer">
            <button
              onClick={() => {
                handleCloseSave();
              }}
              className="btn btn-border"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {isAdd === true ? "Save" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default AddEditMaintainence;
