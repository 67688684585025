import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { RxCross1 } from "react-icons/rx";
import { BsArrowLeft } from "react-icons/bs";
import "./View.scss";
import { useNavigate, useParams } from "react-router-dom";
import Excel from "../../../assets/img/excel.png";
import Word from "../../../assets/img/word.png";
import Pdf from "../../../assets/img/pdf.png";
import AddEditDepartment from "../AddForm/AddEditDepartment";
import DeleteModal from "../../Model/DeleteModal";
import { toast } from "react-toastify";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import FileDisplay from "../../FileInput/FileDisplay";

const DepartmentView = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const params = useParams();
  const { id } = params || {};
  const [department, setDepartment] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [DeleteDepartment, setDeleteDepartment] = useState(false);
  const [deleteImg, setDeleteImg] = useState("");
  const [loading, setLoading] = useState(false);
  const decryptedId = Decryption(id);

  const { company_id, location_id } = useFilter();

  const fetchDepartment = () => {
    setLoading(true);
    try {
      httpService
        .get(`department/get_department/${decryptedId}`)
        .then((response) => {
          // console.log(response.data[0]);
          setDepartment(response?.data[0]);
          setDeleteImg("");
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, [id, showLoginPopup]);

  const handleOpen = () => {
    setOpen(true);
    fetchDepartment();
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteImg("");
    setDeleteDepartment(false);
    fetchDepartment();
  };

  const HandleDeleteDepartment = async () => {
    if (deleteImg !== "" && deleteImg !== undefined) {
      await handleDeleteImg(deleteImg);
    } else {
      try {
        let response = await httpService.delete(
          `${"department/delete_department"}/${decryptedId}`
        );
        if (response) {
          toast.success("Department Deleted successfully", {
            position: "top-right",
          });
          setDeleteDepartment(false);
          fetchDepartment();
          navigate("/department");
        }
      } catch (error) {
        setDeleteDepartment(false);
        console.error("Error deleting department:", error);
        // toast.error(error, {
        //   position: "top-right",
        // });
      }
    }
  };

  const handleDeleteImg = async (imgId) => {
    const newArray = [...department?.attachment]; // Create a copy of the attached array
    newArray.splice(imgId, 1);

    const formData = new FormData();
    for (const key in department) {
      if (key === "attachment") {
        for (let i = 0; i < newArray.length; i++) {
          formData.append(`existFile[${i}]`, newArray[i]);
        }
      } else {
        // Check if sort_order is undefined or null, then set it to ""
        const value =
          key === "sort_order" ? department[key] || "" : department[key];
        if (key === "version") {
          formData.append(key, value === null ? "" : value);
        } else {
          formData.append(key, value);
        }
      }
    }
    try {
      let response = await httpService.put(
        `${"department/edit_department"}/${decryptedId}`,
        formData
      );
      if (response.message) {
        setDeleteDepartment(false);
        fetchDepartment();
        toast.success("File Deleted successfully", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error adding department:", error);
      setDeleteDepartment(false);
      // toast.error(error, {
      //   position: "top-right",
      // });
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="department-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={() => navigate("/department")} />
              </span>
              Department Details - [{department.name ? department.name : "--"}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button
              type="submit"
              className="btn btn-edit"
              onClick={() => handleOpen()}
            >
              <CiEdit /> Edit{" "}
            </button>
            <button
              type="submit"
              className="btn btn-delete"
              onClick={() => {
                setDeleteDepartment(true);
              }}
            >
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{department.name ? department.name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {department?.description ? department?.description : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s)</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {department?.version ? department?.version : "--"}
                  </p>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Company</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>
                      {department?.company_name
                        ? department?.company_name
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Location</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>
                      {department?.location_name
                        ? department?.location_name
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Milestone</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {department?.milestones?.length === 0 ? (
                      <div>--</div>
                    ) : (
                      department?.milestones?.map((milestone, index) => (
                        // Use key prop to provide a unique key for each child
                        <span key={index}>{milestone.name}</span>
                      ))
                    )}
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Activity</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {department?.activities?.length === 0 ? (
                      <div>--</div>
                    ) : (
                      department?.activities?.map((activity, index) => (
                        // Use key prop to provide a unique key for each child
                        <span key={index}>{activity.name}</span>
                      ))
                    )}
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Tasks</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {department?.tasks?.length === 0 ? (
                      <div>--</div>
                    ) : (
                      department?.tasks?.map((activity, index) => (
                        // Use key prop to provide a unique key for each child
                        <span key={index}>{activity.name}</span>
                      ))
                    )}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Files </label>
                    <span>:</span>
                  </div>
                </div>
                <FileDisplay
                  attached={department?.attachment}
                  handleDelete={() => setDeleteDepartment(true)}
                  setDeleteImg={setDeleteImg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddEditDepartment
        open={open}
        handleClose={handleClose}
        clickeddepartment={decryptedId}
        isAdd={false}
        fetchDepartment={fetchDepartment}
        companyId={company_id}
        locationId={location_id}
      />
      <DeleteModal
        open={DeleteDepartment}
        HandleClose={handleClose}
        id={deleteImg}
        HandleDelete={() => HandleDeleteDepartment()}
      />
    </>
  );
};

export default DepartmentView;
