import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import sidebarlog from "../../assets/img/frosted-farms.svg";
import "../Sidebar/Sidebar.scss";
import Dashboard from "../../assets/icons/dashboard.png";
import Company from "../../assets/icons/company.png";
import Milestone from "../../assets/icons/milestone.png";
import Activity from "../../assets/icons/activity.png";
import Room from "../../assets/icons/room.png";
import Employee from "../../assets/icons/employee.png";
import Plant from "../../assets/icons/plant.png";
import Maintenance from "../../assets/icons/maintenance.png";
import Role from "../../assets/icons/role.png";
import Location from "../../assets/icons/location.png";
import Department from "../../assets/icons/department.png";
import Sops from "../../assets/icons/sop.png";
import Schedule from "../../assets/icons/schedule.png";
import Inventory from "../../assets/icons/inventory.png";
import Tasks from "../../assets/icons/task.svg";
import Reports from "../../assets/icons/clipboard-text.svg";

const menuItems = [
  {
    path: "/",
    label: "Dashboard",
    icon: Dashboard,
    roles: [1, 2, 3],
  },
  {
    path: "/company",
    label: "Companies",
    icon: Company,
    roles: [1],
  },
  {
    path: "/location",
    label: "Locations",
    icon: Location,
    roles: [1, 2],
  },
  { path: "/role", label: "Roles", icon: Role, roles: [1, 2, 3] },
  {
    path: "/department",
    label: "Departments",
    icon: Department,
    roles: [1, 2, 3],
  },

  {
    path: "/milestone",
    label: "Milestones",
    icon: Milestone,
    roles: [1, 2, 3],
  },
  {
    path: "/activities",
    label: "Activities",
    icon: Activity,
    roles: [1, 2, 3],
  },
  { path: "/task", label: "Tasks", icon: Tasks, roles: [1, 2, 3] },
  { path: "/plant", label: "Plants", icon: Plant, roles: [1, 2, 3] },
  { path: "/room", label: "Rooms", icon: Room, roles: [1, 2, 3] },
  {
    path: "/work-task",
    label: "Work Tasks",
    icon: Maintenance,
    roles: [1, 2, 3],
  },
  {
    path: "/sops",
    label: "SOPs",
    icon: Sops,
    roles: [1, 2, 3],
  },

  // { path: "/user", label: "Users", icon: Company, roles: [1, 2, 3] },
  {
    path: "/employee",
    label: "Employees",
    icon: Employee,
    roles: [1, 2, 3],
  },
  {
    path: "/schedule",
    label: "Schedule",
    icon: Schedule,
    roles: [1, 2, 3],
  },
  {
    path: "/schedule_conflict",
    label: "Conflicted Schedule",
    icon: Schedule,
    roles: [1, 2, 3],
  },
  {
    path: "/reports",
    label: "Reports",
    icon: Reports,
    roles: [1, 2, 3],
  },
  // {
  //   path: "/inventory",
  //   label: "Inventories",
  //   icon: Inventory,
  //   roles: [1, 2, 3],
  // },
];

const Sidebar = () => {
  const location = useLocation();
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const userRole = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    // Filter menu items based on user role
    const filteredItems = menuItems?.filter((item) => item.roles.includes(userRole?.role_id));
    setFilteredMenuItems(filteredItems);
  }, []);

  return (
    <div className="sidebar_main">
      <div
        className="offcanvas offcanvas-start"
        tab-index="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas_header">
          <div className="header_logo">
            <img src={sidebarlog} alt="logo" />
          </div>
        </div>

        <div className="offcanvas-body">
          <div className="inner_sidebar">
            <div className="menu_list" id="sidebar">
              <ul className="menu_list_main">
                {filteredMenuItems.map((menuItem, index) => (
                  <li className="nav-item" key={index}>
                    <Link
                      to={menuItem.path}
                      className={`ato ${
                        location.pathname === menuItem.path || location.pathname.startsWith(`${menuItem.path}/view/`)
                          ? "active"
                          : ""
                      }`}>
                      <div className="sidebar_icon">
                        <img src={menuItem.icon} alt={menuItem.label} className="" />
                      </div>
                      <span>{menuItem.label}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
