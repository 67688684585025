import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import dayjs from "dayjs";
import moment from "moment";

const handleDownloadEmployeeExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchEmployeeData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`employee/get_employees`)
        .then((response) => {
          if (response?.data) {
            const employeeData = response?.data?.data;
            resolve(employeeData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching employee data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const employeeData = await fetchEmployeeData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Employee");

    // Add headers
    worksheet.addRow([
      "Profile Image",
      "ID",
      "Name",
      "Role",
      "Email",
      "Time",
      "Company",
      "Location",
    ]);
    worksheet.getRow(1).alignment = { horizontal: "center" }; // Center align the text

    if (
      employeeData !== null &&
      employeeData !== undefined &&
      employeeData.length > 0
    ) {
      employeeData.forEach((employee) => {
        worksheet.addRow([
          employee?.profile_photo || "",
          employee?.employee_code || "",
          employee?.name || "",
          employee?.role_name || "", // Updated to role_name
          employee?.email || "",
          employee?.start_time && employee?.end_time
            ? `${dayjs(employee.start_time, "HH:mm:ss").format(
                "h:mm A"
              )} To ${dayjs(employee.end_time, "HH:mm:ss").format("h:mm A")}`
            : "", // Formatting time
          employee?.company_name || "",
          employee?.location_name || "",
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `EmployeeData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadEmployeeExcel };
