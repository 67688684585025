import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import moment from "moment";

const handleDownloadDepartmentExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchDepartmentData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`department/get_department`)
        .then((response) => {
          if (response?.data) {
            const departmentData = response?.data?.data;
            resolve(departmentData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching department data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const departmentData = await fetchDepartmentData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Department");

    // Add headers
    worksheet.addRow(["Name", "Company", "Location"]);
    worksheet.getRow(1).alignment = { horizontal: "center" }; // Center align the text

    if (
      departmentData !== null &&
      departmentData !== undefined &&
      departmentData.length > 0
    ) {
      departmentData.forEach((department) => {
        worksheet.addRow([
          department?.name || "",
          department?.company_name || "",
          department?.location_name || "",
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `DepartmentData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadDepartmentExcel };
