import React from "react";
import SelectInput from "./SelectInput";

const CommonDropdown = ({
  companyId,
  locations,
  milestones,
  activities,
  handleBlur,
  setFieldValue,
  companies,
  errorCompany,
  errorLocation,
  errorMilestone,
  errorActivity,
  handleChangeLocation,
  locationId,
  valuesCompany,
  valuesLocation,
  valuesActivity,
  valuesMilestone,
  sort_order,
  rows,
  setApiError,
  matchSequenceOrder,
  autoSequenceOrder,
  com_id,
  loc_id,
  act_id,
  mil_id,
  valuesEmployee,
}) => {
  return (
    <>
      <div className="col-md-6">
        <SelectInput
          label={"Company"}
          name={com_id}
          required={true}
          disabled={companyId > 0 ? true : false}
          value={valuesCompany}
          multiple={false}
          onBlur={handleBlur}
          options={companies}
          error={errorCompany}
          onChange={(event) => {
            setFieldValue(com_id, event.target.value);
            setFieldValue(loc_id, "");
            setFieldValue(mil_id, []);
            setFieldValue(act_id, []);
            handleChangeLocation(com_id, event.target.value);
          }}
        />
      </div>

      <div className="col-md-6">
        <SelectInput
          label={"Location"}
          name={loc_id}
          required={true}
          disabled={locationId > 0 ? true : false}
          value={valuesLocation}
          multiple={false}
          onBlur={handleBlur}
          options={locations}
          error={errorLocation}
          onChange={(event) => {
            setFieldValue(loc_id, event.target.value);
            setFieldValue(mil_id, []);
            setFieldValue(act_id, []);
            handleChangeLocation(loc_id, event.target.value);
            const payload = {
              company_id: valuesCompany,
              location_id: event.target.value,
              sort_order: sort_order,
              id: rows?.id,
            };
            if (valuesCompany && sort_order && event.target.value) {
              matchSequenceOrder(payload);
            } else {
              setApiError("");
            }
            if (!rows?.id) {
              autoSequenceOrder(payload, setFieldValue);
            }
          }}
        />
      </div>

      <div className="col-md-6">
        <SelectInput
          label={"Milestones"}
          name={mil_id}
          required={valuesEmployee && valuesEmployee?.length > 0 ? false : true}
          value={valuesMilestone}
          multiple={true}
          selectAll={true}
          onBlur={handleBlur}
          options={milestones}
          error={errorMilestone}
          valuesEmployee={valuesEmployee}
          onChange={(e) => {
            const value = e.target.value;
            const milestone = milestones.filter((item) =>
              value.includes(item.id)
            );
            if (valuesActivity?.length > 0) {
              const activity = activities.filter((item) =>
                valuesActivity.includes(item.id)
              );
              const filteredActivities = activity?.filter((activity) =>
                milestone
                  .map((milestone) => milestone.id)
                  .includes(activity.milestone_id)
              );
              const selectedActivity = filteredActivities?.map(
                (item) => item.id
              );
              setFieldValue(act_id, selectedActivity);
            }
            if (value[value.length - 1] === "all") {
              const selectedIds =
                valuesMilestone?.length === milestones.length
                  ? []
                  : milestones.map((item) => item.id);
              handleChangeLocation(mil_id, selectedIds);
              setFieldValue(mil_id, selectedIds);
              // setFieldValue(act_id, []);
              return;
            }
            setFieldValue(mil_id, e.target.value);
            handleChangeLocation(mil_id, e.target.value);
          }}
        />
      </div>

      <div className="col-md-6">
        <SelectInput
          label={"Activities"}
          name={act_id}
          required={valuesEmployee && valuesEmployee?.length > 0 ? false : true}
          value={valuesActivity}
          multiple={true}
          selectAll={true}
          onBlur={handleBlur}
          options={activities}
          error={errorActivity}
          onChange={(e) => {
            const value = e.target.value;
            if (value[value.length - 1] === "all") {
              const selectedIds =
                valuesActivity?.length === activities.length
                  ? []
                  : activities.map((item) => item.id);
              setFieldValue(act_id, selectedIds);
              handleChangeLocation(act_id, selectedIds);
              return;
            }
            setFieldValue(act_id, e.target.value);
            handleChangeLocation(act_id, e.target.value);
          }}
        />
      </div>
    </>
  );
};

export default CommonDropdown;
