import React, { useEffect, useState } from "react";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import { HiOutlineEye } from "react-icons/hi2";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { RxPlus } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import "./Activities.scss";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  gridClasses,
  styled,
} from "@mui/material";
import { getFileName } from "../../../utils/common";
import { HttpService } from "../../../service/HttpService";
import { useAuth } from "../../../context/AuthProvider";
import DeleteModal from "../../../components/Model/DeleteModal";
import AddEditActivity from "../../../components/Activity/AddEditActivity";
import { displayDuration } from "../../../Mixin/DisplayDuration";
import { Encryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import { default as ReactSelect, components } from "react-select";
import { handleDownloadExcel } from "../DownloadActivity";
import { SiMicrosoftexcel } from "react-icons/si";

const Activities = () => {
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsMilstonename, setMilstonename] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [activity_id, setActivity_Id] = useState("");
  const [DeleteActivity, setDeleteActivity] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const { company_id, location_id } = useFilter();
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState("");
  const [page, setPage] = useState(0);
  const [columnName, setColumnName] = useState("");
  const [sortOrder, setsortOrder] = useState("");
  const [Milstone, setMilstone] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [allIds, setAllIds] = useState(0);

  const fetchActivity = () => {
    setLoading(true);
    httpService
      .get(
        `activity/get_activity?milestoneFilter=${Milstone}&page=${
          page + 1
        }&pageSize=${pageSize}&search=${searchData}&sortBy=${columnName}&sortOrder=${sortOrder}`
      )
      .then((response) => {
        setRows(response?.data?.data); // Assuming the response data is an array of rows
        // setSearchData(response?.data);
        setRowCount(response?.data?.pagination?.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const fetchMilestone = () => {
    setLoading(true);
    httpService
      .get(`milestone/get_milestones`)
      .then((response) => {
        const OptionsValues = response?.data?.data?.map((items) => {
          return {
            label: items.name,
            value: items.id,
          };
        });

        setMilstonename(OptionsValues);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchActivity();
    fetchMilestone();
    setAllIds(0);
    setSelectedId([]);
    setIsAllSelected(false);
  }, [company_id, location_id, showLoginPopup, page, pageSize, searchData, columnName, sortOrder, Milstone]);
  const handleMilstone = (event) => {
    setMilstone(event?.target?.value);
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpenModel(true);
    setIsAdd(true);
    setActivity_Id("");
  };
  const handleClose = () => {
    setOpenModel(false);
    setIsAdd(false);
    setActivity_Id("");
    setOpenDelete(false);
    fetchActivity();
  };

  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    // Navigate to the encrypted URL
    navigate(`view/${urlEncodedId}`);
  };

  // const searchActivities = (searchTerm) => {
  //   const searchTermLowerCase = searchTerm.toLowerCase();
  //   const results =
  //     rows?.length > 0 &&
  //     rows?.filter((item) => {
  //       for (let key in item) {
  //         if (
  //           typeof item[key] === "string" &&
  //           item[key].toLowerCase().includes(searchTermLowerCase)
  //         ) {
  //           return true;
  //         }
  //       }
  //       return false;
  //     });
  //   if (results) {
  //     setSearchData(results);
  //   }
  // };

  const HandleDeleteActivity = async () => {
    if (DeleteActivity) {
      try {
        const response = await httpService.delete(`${"activity/delete_activity"}/${DeleteActivity}`);
        if (response) {
          fetchActivity();
          navigate("/activities");
          toast.success(response?.message, {
            position: "top-right",
          });
        } else {
          throw response;
        }
        setOpenDelete(false);
        fetchActivity();
      } catch (error) {
        setOpenDelete(false);
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    }
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const [state, setState] = useState({ optionSelected: null });

  const handleChange = (selected) => {
    setState({
      optionSelected: selected,
    });
    const selectedId = selected.map((item) => item.value);
    setMilstone(selectedId);
  };

  const Option = (props) => {
    return (
      <div style={{ zIndex: 9999 }}>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const onDownloadClick = async () => {
    try {
      await handleDownloadExcel(setShowLoginPopup);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  const handleHeaderCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const allIds = rows.map((row) => row.id); // Get all the IDs from the data
      setSelectedId(allIds); // Select all IDs
      console.log("Total selected IDs: ", allIds.length);
      setAllIds(allIds.length);
    } else {
      setSelectedId([]); // Deselect all
      console.log("Total selected IDs: 0");
    }

    setIsAllSelected(isChecked);
  };

  const toggleId = (id) => {
    setSelectedId((prev) => {
      if (prev.includes(id)) {
        const updatedSelectedIds = prev.filter((item) => item !== id);
        if (updatedSelectedIds.length < allIds) {
          setIsAllSelected(false);
        }
        return updatedSelectedIds;
      } else {
        const updatedSelectedIds = [...prev, id];
        if (updatedSelectedIds.length === allIds) {
          setIsAllSelected(true);
        }
        return updatedSelectedIds;
      }
    });
  };

  const isIdSelected = (id) => {
    return selectedId.includes(id);
  };

  const deleteSelectedActvity = async () => {
    if (selectedId?.length > 0) {
      try {
        const response = await httpService.post("activity/delete_all_activity", { activityIds: selectedId });
        if (response?.success) {
          fetchActivity();
          const messages = response?.data?.combinedMessage ? response?.data?.combinedMessage : response?.data?.message;
          response?.data?.combinedMessage
            ? toast.error(messages, {
                position: "top-right",
              })
            : toast.success(messages, {
                position: "top-right",
              });
        } else {
          throw response;
        }
        fetchActivity();
      } catch (error) {
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    } else {
      toast.error("Please select atleast one milestone", {
        position: "top-right",
      });
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="activities">
        <div className="page-title">
          <div className="title-wrap">
            <h1>Activities</h1>
          </div>

          <div className="right_part align-items-end">
            <div className="form_field_wrapper">
              {/* <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Milestone</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Milstone}
                    label="Milestone"
                    onChange={handleMilstone}
                  >
                    <MenuItem value="" disabled selected>
                      Select Milestone
                    </MenuItem>
                    {rowsMilstonename?.length > 0 &&
                      rowsMilstonename?.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              <InputLabel id="demo-simple-select-label">Milestone</InputLabel>
              <ReactSelect
                labelId="demo-simple-select-label"
                options={rowsMilstonename}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                onChange={handleChange}
                value={state.optionSelected}
                label="Milestones"
                className="selectionbox"
              />
              {/* <SelectInput
                label={"Milestones"}
                name={"milestone_id"}
                required={false}
                value={Milstone}
                multiple={true}
                selectAll={true}
                // onBlur={true}
                options={rowsMilstonename}
                // error={errorMilestone}
                valuesEmployee={[]}
                onChange={(e) => {
                  const value = e.target.value;
                  const milestone = rowsMilstonename?.filter((item) =>
                    value.includes(item.id)
                  );

                  if (value[value.length - 1] === "all") {
                    const selectedIds =
                      Milstone?.length === rowsMilstonename.length
                        ? []
                        : rowsMilstonename.map((item) => item.id);
                    // handleChangeLocation(mil_id, selectedIds);
                    // setFieldValue(mil_id, selectedIds);
                    setMilstone(selectedIds);
                    // setFieldValue(act_id, []);
                    return;
                  }
                  setMilstone(e.target.value);
                }}
              /> */}
            </div>
            <div className="button-wrap">
              <button type="submit" className="btn btn-primary" onClick={() => handleOpen()}>
                <RxPlus /> Add Activities
              </button>
            </div>
          </div>
        </div>
        <div className="table-filter-wrap d-flex justify-content-between">
          <Button className="btn btn-delete" onClick={() => deleteSelectedActvity()}>
            Delete
          </Button>
          <div className="d-flex flex-row">
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDownloadClick()}
              startIcon={<SiMicrosoftexcel />}
              className="me-3">
              Download
            </Button>
            <div className="header_search-wrap">
              <div className="header_search_bar common_content">
                <div className="input-group input_group">
                  <button className="btn search-btn search_btn" style={{ zIndex: 0 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21 21" fill="none">
                      <circle
                        cx="9.98856"
                        cy="9.98856"
                        r="8.98856"
                        stroke="#495057"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.2402 16.7071L19.7643 20.222"
                        stroke="#495057"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <input
                    type="search"
                    className="form-control form_control"
                    placeholder="Search..."
                    aria-label="Search"
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchData(value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: `${rows?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}>
          <DataGrid
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              rows?.length > 0 &&
              rows?.map((row, index) => ({
                ...row,
                id: row.id,
                sequenceNumber: index + 1,
              }))
            }
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "select_All",
                headerName: "Select All",
                minWidth: 150,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  return (
                    <Checkbox
                      value="Checked"
                      checked={isAllSelected ? true : isIdSelected(params.row.id) ? true : false}
                      onChange={() => toggleId(params.row.id)}
                    />
                  );
                },
                headerCheckboxSelection: true,
                renderHeader: (params) => {
                  return (
                    <>
                      <Checkbox checked={isAllSelected} onChange={(e) => handleHeaderCheckboxChange(e)} />
                      <strong>Select All</strong>
                    </>
                  );
                },
              },
              { field: "name", headerName: "Name", flex: 1, minWidth: 200 },
              {
                field: "duration",
                headerName: "Duration",
                flex: 1,
                minWidth: 180,
                sortable: true,
                renderCell: (params) => {
                  // console.log(params.row);
                  const duration = displayDuration(params.row.days, params.row.hours, params.row.minutes);
                  return <div>{duration}</div>;
                },
              },
              {
                field: "tasks",
                headerName: "Tasks",
                width: 300,
                renderCell: (params) => {
                  const tasks = params?.value || []; // Use a hyphen if description is falsy
                  const task_list = tasks.map((tasks) => tasks?.task_name || "--").join(", ");

                  return <div>{task_list}</div>;
                },
              },
              {
                field: "milestone_name",
                headerName: "Milestone",
                flex: 1,
                minWidth: 200,
                sortable: true,
                renderCell: (params) => {
                  const milestone = params?.value || "--"; // Use a hyphen if company is falsy
                  return <div>{milestone}</div>;
                },
              },
              {
                field: "company_name",
                headerName: "Company",
                flex: 1,
                minWidth: 200,
                sortable: true,
                renderCell: (params) => {
                  const company = params?.value || "--"; // Use a hyphen if company is falsy
                  return <div>{company}</div>;
                },
              },
              {
                field: "location_name",
                headerName: "Location",
                flex: 1,
                minWidth: 200,
                sortable: true,
                renderCell: (params) => {
                  const location = params?.value || "--"; // Use a hyphen if location is falsy
                  return <div>{location}</div>;
                },
              },
              {
                field: "attached",
                headerName: "Files",
                width: 265,
                disableColumnMenu: true,
                renderCell: (params) => {
                  // console.log(params.value);
                  const attachedFiles = Array.isArray(params.value) ? params.value : [];

                  // If there are no attached files, return an empty div
                  if (!attachedFiles || attachedFiles.length === 0) {
                    return <div> -- </div>;
                  }
                  // console.log(attachedFiles);
                  return (
                    <div className="file-weap">
                      {attachedFiles.slice(0, 2).map((file, index) => (
                        <span key={index}>{getFileName(file)}</span>
                      ))}
                      {attachedFiles.length > 2 && <span className="counter-tag">+{attachedFiles.length - 2}</span>}
                    </div>
                  );
                },
                sortable: false,
              },
              {
                field: "action",
                headerName: "Actions",
                headerAlign: "center",
                align: "center",
                width: 100,
                disableColumnMenu: true,
                renderCell: (params) => (
                  <>
                    <div className="action_icon-weap">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e), setDataId(params.row.id);
                        }}>
                        <GridMoreVertIcon />
                      </IconButton>
                    </div>
                    {anchorEl && dataId === params?.row?.id && (
                      <Popover
                        className="popup-menu"
                        key={dataId}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}>
                        <MenuItem
                          onClick={() => {
                            viewPage(dataId);
                          }}
                          className="menu-list-items">
                          <div className="menu-list-items-text">View</div>
                          <HiOutlineEye style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // console.log("params", params.row.id);
                            setOpenModel(true);
                            setIsAdd(false);
                            setActivity_Id(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items">
                          <div className="menu-list-items-text">Edit</div>
                          <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenDelete(true);
                            setDeleteActivity(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items">
                          <div className="menu-list-items-text">Delete</div>
                          <GoTrash style={{ cursor: "pointer" }} />
                        </MenuItem>
                      </Popover>
                    )}
                  </>
                ),
                sortable: false,
              },
            ]}
            initialState={{
              pagination: {
                rowCount: -1,
              },
            }}
            onSortModelChange={(e) => {
              if (e && e.length > 0) {
                setsortOrder(e[0].sort);
                setColumnName(e[0].field);
              } else {
                setsortOrder("");
                setColumnName("");
              }
            }}
            paginationMode="server"
            sortingMode="server"
            rowCount={rowCount}
            pageSizeOptions={[15, 50, 100]}
            paginationModel={{ pageSize: pageSize, page: page }}
            onPaginationModelChange={(event) => {
              setPage(event?.page);
              setPageSize(event?.pageSize);
            }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>
      {openModel === true && (
        <AddEditActivity
          open={openModel}
          isAdd={isAdd}
          activity_id={activity_id}
          handleClose={() => handleClose()}
          fetchActivity={() => fetchActivity()}
          companyId={company_id}
          locationId={location_id}
        />
      )}
      <DeleteModal
        open={openDelete}
        HandleClose={() => handleClose()}
        HandleDelete={() => {
          HandleDeleteActivity(), setActivity_Id("");
        }}
      />
    </>
  );
};

export default Activities;
