import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { RxCross1 } from "react-icons/rx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Checkbox, FormControl, ListItemText, MenuItem, Select } from "@mui/material";
import { HttpService } from "../../../service/HttpService";
import FileInput from "../../FileInput/FileInput";
import { useAuth } from "../../../context/AuthProvider";

function AddEditSops({ open, handleClose, clickedsops = "", isAdd, fetchSops, companyId, locationId }) {
  const [milestoneNames, setMilestoneNames] = useState([]);
  const [activityNames, setActivityNames] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [maintenances, setMaintenances] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [apiError, setApiError] = useState("");
  const [selectAllMilestone, setSelectAllMilestone] = useState(false);
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);

  const autoSequenceOrder = async (payload) => {
    return await httpService
      .post("sops/check-auto-sequence", payload)
      .then((response) => {
        if (response?.data?.max_sort_order) {
          formik.setFieldValue("sort_order", response?.data?.max_sort_order);
        } else {
          formik.setFieldValue("sort_order", "");
        }
        return response?.data?.max_sort_order;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  useEffect(() => {
    fetchCompanies();
    if (companyId > 0) {
      getLocationByCompany(companyId);
    }
    if (locationId > 0) {
      getMilestoneByLocation([parseInt(locationId)]);
    }
  }, [companyId, locationId, showLoginPopup]);

  //fetch data for edit time
  useEffect(() => {
    // console.log("useeffect running");
    if (clickedsops !== "") {
      httpService.get(`sops/get_sop/${clickedsops}`).then((response) => {
        formik.setValues({
          name: response?.data[0].name,
          activity: response?.data[0].activity_id || [],
          milestone: response?.data[0].milestone_id || [],
          company: response?.data[0].company_id,
          location: response?.data[0].location_id || [],
          description: response?.data[0].description || "",
          maintenances: response?.data[0].maintenance_id || [],
          tasks: (response?.data[0].tasks?.length > 0 && response?.data[0].tasks.map((item) => item.id)) || [],
          attached: response?.data[0].attachment || [],
          sort_order: response?.data[0].sort_order,
          version: response?.data[0].version || "",
        });
        if (response) {
          if (response?.data[0]?.activity_id?.length > 0) {
            getTaskByActivity(response?.data[0]?.activity_id);
            getMaintenanceByActivity(response?.data[0]?.activity_id);
          }
          if (response?.data[0]?.milestone_id?.length > 0) {
            getActivityByMilestone(response?.data[0]?.milestone_id);
          }
          if (response?.data[0]?.company_id) {
            getLocationByCompany(response?.data[0]?.company_id);
          }
          if (response?.data[0]?.location_id?.length > 0) {
            getMilestoneByLocation(response?.data[0]?.location_id);
          }
        }
      });
    } else {
      formik.resetForm();
    }
  }, [clickedsops]);

  const fetchCompanies = () => {
    httpService
      .get("company/get_companies")
      .then((response) => {
        setCompanies(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleDropdownChange = (key, id) => {
    if (key === "company") {
      // Call the API to fetch location data based on the selected company_id
      getLocationByCompany(id);
      formik.setFieldValue("location", []);
      formik.setFieldValue("milestone", []);
      formik.setFieldValue("activity", []);
      formik.setFieldValue("tasks", []);
      formik.setFieldValue("maintenances", []);
      setLocations([]);
      setMilestoneNames([]);
      setActivityNames([]);
      setTasks([]);
    }

    if (key === "location") {
      // Call the API to fetch milestone data based on the selected location_id
      getMilestoneByLocation(id);
      setSelectAllMilestone(false);
      setMilestoneNames([]);
      setActivityNames([]);
    }

    if (key === "milestone") {
      if (!selectAllMilestone) {
        getActivityByMilestone(id);
      }
    }

    if (key === "activity") {
      getTaskByActivity(id);
      getMaintenanceByActivity(id);
    }
  };

  const getLocationByCompany = (id) => {
    return httpService
      .get(`location/get_location/company/${id}`)
      .then((response) => {
        setLocations(response?.data);
        if (response?.data.length === 0) {
          formik.setFieldValue("location", []);
        }
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  };

  const getMilestoneByLocation = (id) => {
    return httpService
      .post(`sops/get_milestone/location`, { locationId: id })
      .then((response) => {
        setMilestoneNames(response?.data);
        if (response?.data.length === 0) {
          formik.setFieldValue("milestone", "");
        }
      })
      .catch((error) => {
        console.error("Error fetching milestone data:", error);
        throw error; // Re-throw the error to handle it upstream if needed
      });
  };

  const getActivityByMilestone = (id) => {
    return httpService
      .post(`activity/get_activity/milestone`, { milestone_id: id })
      .then((response) => {
        setActivityNames(response);
        if (response.length === 0) {
          formik.setFieldValue("activity", []);
          formik.setFieldValue("maintenances", []);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const getTaskByActivity = (id) => {
    return httpService
      .post(`task/get_tasks/activity`, { activity_id: id })
      .then((response) => {
        setTasks(response);
        if (response.length === 0) {
          formik.setFieldValue("tasks", "");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const getMaintenanceByActivity = (id) => {
    return httpService
      .post(`maintenance/get_maintenance/activity`, { activity_id: id })
      .then((response) => {
        setMaintenances(response?.data);
        if (response.length === 0) {
          formik.setFieldValue("maintenances", []);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const matchSequenceOrder = async (payload) => {
    return await httpService
      .post("sops/check-sequence", payload)
      .then((response) => {
        setApiError("");
        return response;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setApiError(error?.message);
        return error;
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      activity: [],
      milestone: [],
      maintenances: [],
      tasks: [],
      company: (companyId > 0 && companyId) || "",
      location: (parseInt(locationId) > 0 && [parseInt(locationId)]) || [],
      description: "",
      file: [],
      attached: "",
      sort_order: "",
      version: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Please Enter SOPs Name")
        .max(200, "SOPs Name must be less than 200 characters"),
      company: Yup.string().required("Please Select Company"),

      description: Yup.string().max(2000, "Description must be less than 2000 characters"),
      file: Yup.mixed()
        // .required("Attachment Is Required")
        .test("fileSize", "Attachment size must be less than 10MB", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          return files.every((file) => file.size <= 10485760); // Check size of each file
        })
        .test("fileFormat", "Invalid file format", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          if (files.length === 0) return true; // Skip if no files provided
          const allowedExtensions = {
            video: ["mp4", "webm", "mov"],
            pdf: ["pdf"],
            document: ["doc", "docx"],
            spreadsheet: ["xls", "xlsx", "csv"],
            image: ["jpg", "png", "jpeg", "gif", "webp"],
          };
          return files.every((file) => {
            if (!file || !file.name) return false; // If file or file name is undefined, return false
            const fileExtension = file?.name?.split(".").pop().toLowerCase();
            const fileType = Object.keys(allowedExtensions).find((type) =>
              allowedExtensions[type].includes(fileExtension)
            );
            return !!fileType;
          });
        }),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("name", values.name || "");
      formData.append("company_id", values.company || "");
      // formData.append("location_id", values.location || "");
      if (formik.values?.location && formik.values?.location?.length > 0) {
        formik.values?.location?.map((item, index) => {
          formData.append(`location_id[${index}]`, item);
        });
      }

      if (formik.values?.activity && formik.values?.activity?.length > 0) {
        formik.values?.activity?.map((item, index) => {
          formData.append(`activity_id[${index}]`, item);
        });
      }

      if (formik.values?.milestone && formik.values?.milestone?.length > 0) {
        formik.values?.milestone?.map((item, index) => {
          formData.append(`milestone_id[${index}]`, item);
        });
      }

      if (formik.values?.maintenances && formik.values?.maintenances?.length > 0) {
        formik.values?.maintenances?.map((item, index) => {
          formData.append(`maintenance_id[${index}]`, item);
        });
      }
      formData.append("description", values.description || "");
      if (values.tasks && values.tasks.length > 0) {
        values.tasks.forEach((item, index) => {
          formData.append(`task_id[${index}]`, item);
        });
      } else {
        formData.append("task_id", []);
      }
      formData.append("sort_order", values.sort_order || "");
      formData.append("version", values.version || "");
      // Check if new files are provided
      if (values.file && values.file.length > 0) {
        for (let i = 0; i < values.file.length; i++) {
          formData.append("attachment", values.file[i]);
        }
      } else {
        formData.append("attachment", []);
      }
      //Runs only for edit purpose
      if (values.attached) {
        if (clickedsops !== "" && !isAdd) {
          for (let i = 0; i < values.attached.length; i++) {
            formData.append(`existFile[${i}]`, values.attached[i]);
          }
        }
      }
      if (!apiError) {
        try {
          if (isAdd) {
            await httpService.post("sops/add_sop", formData).then((response) => {
              // console.log(res);
              if (response) {
                toast.success("SOP Added Successfully", {
                  position: "top-right",
                });
                formik.resetForm();
                handleClose();
                fetchSops();
              }
            });
          } else {
            await httpService.put(`${"sops/edit_sop"}/${clickedsops}`, formData).then((res) => {
              // console.log(res);
              if (res) {
                toast.success("SOP Updated Successfully", {
                  position: "top-right",
                });
                // formik.resetForm();
                handleClose();
                fetchSops();
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    if (formik?.values?.sort_order === "" && companyId > 0 && locationId > 0 && clickedsops === "") {
      autoSequenceOrder({ company_id: companyId, location_id: locationId }, formik.setFieldValue);
    }
  }, [companyId, locationId, formik.values.sort_order]);

  const handleCloseSave = () => {
    formik.resetForm();
    handleClose();
    setLocations([]);
    setActivityNames([]);
    setTasks([]);
  };

  return (
    <>
      <Modal
        className="sops-modal"
        open={open}
        onClose={() => {
          handleCloseSave();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <div className="modal_block">
            <div className="modal_header">
              <div className="modal_title_wrapper">
                <h2 className="modal_title">{isAdd === true ? "Add SOPs" : "Edit SOPs"}</h2>
              </div>
              <div className="cancel-btn-wrap">
                <RxCross1
                  onClick={() => {
                    handleCloseSave();
                  }}
                />
              </div>
            </div>
            <div className="modal_content">
              <div className="row">
                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>
                      Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      id="name"
                      InputProps={{}}
                      placeholder="Enter SOPs Name"
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div style={{ color: "red" }}>{formik.errors.name}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>
                      Company<span style={{ color: "red" }}>*</span>
                    </label>
                    <FormControl fullWidth disabled={companyId > 0 ? true : false}>
                      <Select
                        id="demo-simple-select"
                        name="company_id"
                        value={formik.values.company || ""}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          keepMounted: true,
                        }}
                        onChange={(event) => {
                          formik.setFieldValue("company", event.target.value);
                          handleDropdownChange("company", event.target.value);
                          const payload = {
                            company_id: event.target.value,
                            sort_order: formik.values?.sort_order,
                            id: clickedsops,
                          };
                          if (formik.values?.sort_order && event.target.value) {
                            matchSequenceOrder(payload);
                          } else {
                            setApiError("");
                          }
                          if (clickedsops === "") {
                            autoSequenceOrder(payload);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        displayEmpty>
                        <MenuItem value="" selected disabled>
                          Select Company
                        </MenuItem>
                        {companies?.length > 0 &&
                          companies?.map((data) => (
                            <MenuItem key={data.id} value={data.id}>
                              {data.company_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.touched.company && formik.errors.company ? (
                      <div style={{ color: "red" }}>{formik.errors.company}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>Location</label>
                    <FormControl fullWidth disabled={locationId > 0 ? true : false}>
                      <Select
                        id="demo-simple-select"
                        name="location_id"
                        value={formik.values.location || []}
                        multiple
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          keepMounted: true,
                        }}
                        onChange={(event) => {
                          formik.setFieldValue("tasks", []);
                          const value = event.target.value;
                          const location = locations.filter((item) => value.includes(item.id));
                          if (formik?.values?.milestone?.length > 0) {
                            const milestone = milestoneNames.filter((item) =>
                              formik?.values?.milestone.includes(item.id)
                            );
                            const filteredMilestone = milestone?.filter((milestone) =>
                              location.map((location) => location.id).includes(milestone.location_id)
                            );
                            const selectedMilestone = filteredMilestone?.map((item) => item.id);
                            formik?.setFieldValue("milestone", selectedMilestone);
                            if (formik?.values?.activity?.length > 0) {
                              handleDropdownChange("milestone", selectedMilestone);
                              const activity = activityNames.filter((item) => {
                                return formik?.values?.activity.includes(item.id);
                              });
                              const filteredActivity = activity.filter((activity) =>
                                filteredMilestone.map((milestone) => milestone.id).includes(activity.milestone_id)
                              );
                              const selectedActivity = filteredActivity?.map((item) => item.id);
                              formik?.setFieldValue("activity", selectedActivity);
                              if (formik?.values?.tasks?.length > 0) {
                                handleDropdownChange("activity", selectedActivity);
                                const task = tasks.filter((item) => {
                                  return formik?.values?.tasks.includes(item.id);
                                });
                                const filteredTask = task.filter((task) =>
                                  filteredActivity.map((activity) => activity.id).includes(task.activity_id)
                                );
                                const selectedTask = filteredTask?.map((item) => item.id);
                                formik?.setFieldValue("tasks", selectedTask);
                              }
                            }
                          }
                          if (value[value.length - 1] === "all") {
                            const selectedIds =
                              formik?.values?.location?.length === locations.length
                                ? []
                                : locations.map((item) => item.id);
                            handleDropdownChange("location", selectedIds);
                            formik.setFieldValue("location", selectedIds);
                            return;
                          }
                          formik.setFieldValue("location", event.target.value);
                          handleDropdownChange("location", event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span>Select Location</span>;
                          }
                          const selectedData = selected
                            .map((id) => locations.find((item) => item.id === id)?.name)
                            .filter((name) => name !== undefined) // Filter out undefined names
                            .join(", ");

                          return selectedData;
                        }}>
                        <MenuItem value="" selected disabled>
                          Select Location
                        </MenuItem>
                        {locations?.length > 0 && (
                          <MenuItem value="all">
                            <Checkbox
                              checked={formik?.values?.location?.length === locations?.length}
                              indeterminate={
                                formik?.values?.location?.length > 0 &&
                                formik?.values?.location?.length < locations?.length
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {/* List of Milestones */}
                        {locations?.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            <Checkbox
                              checked={
                                formik.values?.location?.length > 0 && formik.values?.location?.includes(item.id)
                              }
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.touched.location && formik.errors.location ? (
                      <div style={{ color: "red" }}>{formik.errors.location}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label htmlFor="room-milestone">Milestones Name</label>
                    <FormControl fullWidth>
                      <Select
                        id="room-milestone"
                        multiple
                        name="milestone"
                        value={formik.values?.milestone || []}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          keepMounted: true,
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          const milestone = milestoneNames.filter((item) => value.includes(item.id));
                          if (formik?.values?.activity?.length > 0) {
                            const activities = activityNames.filter((item) => {
                              return formik?.values?.activity.includes(item.id);
                            });
                            const filteredActivity = activities.filter((activity) =>
                              milestone.map((milestone) => milestone.id).includes(activity.milestone_id)
                            );
                            const selectedActivity = filteredActivity?.map((item) => item.id);
                            formik.setFieldValue("activity", selectedActivity);
                            if (formik?.values?.tasks?.length > 0) {
                              handleDropdownChange("activity", selectedActivity);
                              const task = tasks.filter((item) => {
                                return formik?.values?.tasks.includes(item.id);
                              });
                              const filteredTask = task.filter((task) =>
                                filteredActivity.map((activity) => activity.id).includes(task.activity_id)
                              );
                              const selectedTask = filteredTask?.map((item) => item.id);
                              formik.setFieldValue("tasks", selectedTask);
                            }
                          }

                          if (value[value.length - 1] === "all") {
                            const selectedIds =
                              formik?.values?.milestone?.length === milestoneNames.length
                                ? []
                                : milestoneNames.map((item) => item.id);
                            handleDropdownChange("milestone", selectedIds);
                            handleDropdownChange("activity", selectedIds);
                            formik.setFieldValue("milestone", selectedIds);
                            return;
                          }
                          formik.setFieldValue("milestone", e.target.value);
                          handleDropdownChange("milestone", e.target.value);
                        }}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span>Select Milestone</span>;
                          }
                          const selectedData = selected
                            .map((id) => milestoneNames.find((item) => item.id === id)?.name)
                            .filter((name) => name !== undefined) // Filter out undefined names
                            .join(", ");

                          return selectedData;
                        }}>
                        <MenuItem value="" selected disabled>
                          Select Milestone
                        </MenuItem>
                        {/* Select All Checkbox */}
                        {milestoneNames?.length > 0 && (
                          <MenuItem value="all">
                            <Checkbox
                              checked={formik.values?.milestone?.length === milestoneNames?.length}
                              indeterminate={
                                formik.values?.milestone?.length > 0 &&
                                formik.values?.milestone?.length < milestoneNames?.length
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {/* List of Milestones */}
                        {milestoneNames?.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            <Checkbox
                              checked={
                                formik.values?.milestone?.length > 0 && formik.values?.milestone?.includes(item.id)
                              }
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.touched.milestone && formik.errors.milestone ? (
                      <div style={{ color: "red" }}>{formik.errors.milestone}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>Activity Name</label>
                    <FormControl fullWidth>
                      <Select
                        id="demo-simple-select"
                        name="activity"
                        displayEmpty
                        multiple
                        value={formik.values.activity}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          keepMounted: true,
                        }}
                        onChange={(e) => {
                          formik.setFieldValue("maintenances", []);
                          formik.setFieldValue("tasks", []);
                          const value = e.target.value;
                          const activity = activityNames.filter((item) => value.includes(item.id));
                          if (formik?.values?.tasks?.length > 0) {
                            const task = tasks.filter((item) => {
                              return formik?.values?.tasks.includes(item.id);
                            });
                            const filteredTask = task.filter((task) =>
                              activity.map((activity) => activity.id).includes(task.activity_id)
                            );
                            const selectedTask = filteredTask?.map((item) => item.id);
                            formik?.setFieldValue("tasks", selectedTask);
                          }
                          if (value[value.length - 1] === "all") {
                            const selectedIds =
                              formik?.values?.activity?.length === activityNames.length
                                ? []
                                : activityNames.map((item) => item.id);
                            handleDropdownChange("activity", selectedIds);
                            formik.setFieldValue("activity", selectedIds);
                            return;
                          }
                          formik.setFieldValue("activity", e.target.value);
                          handleDropdownChange("activity", e.target.value);
                        }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span>Select Activities</span>;
                          }
                          const selectedData = selected
                            .map((id) => activityNames.find((item) => item.id === id)?.prefix_name)
                            .filter((name) => name !== undefined) // Filter out undefined names
                            .join(", ");

                          return selectedData;
                        }}
                        onBlur={formik.handleBlur}>
                        <MenuItem value="" selected disabled>
                          Select Activity
                        </MenuItem>
                        {activityNames?.length > 0 && (
                          <MenuItem value="all">
                            <Checkbox
                              checked={formik.values?.activity?.length === activityNames?.length}
                              indeterminate={
                                formik.values?.activity?.length > 0 &&
                                formik.values?.activity?.length < activityNames?.length
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {activityNames?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={formik.values?.activity?.includes(item.id)} />
                            <ListItemText primary={item.prefix_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.touched.activity && formik.errors.activity ? (
                      <div style={{ color: "red" }}>{formik.errors.activity}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>Task Name</label>
                    <FormControl fullWidth>
                      <Select
                        id="demo-simple-select"
                        name="tasks"
                        displayEmpty
                        multiple
                        value={formik.values.tasks || []}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (value[value.length - 1] === "all") {
                            const selectedIds =
                              formik?.values?.tasks?.length === tasks.length ? [] : tasks.map((item) => item.id);
                            handleDropdownChange("tasks", selectedIds);
                            formik.setFieldValue("tasks", selectedIds);
                            return;
                          }
                          formik.setFieldValue("tasks", event.target.value);
                        }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          keepMounted: true,
                        }}
                        onBlur={formik.handleBlur}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span>Select Tasks</span>;
                          }
                          const selectedData = selected
                            .map((id) => tasks.find((item) => item.id === id)?.prefix_name)
                            .filter((name) => name !== undefined) // Filter out undefined names
                            .join(", ");

                          return selectedData;
                        }}>
                        <MenuItem value="" selected disabled>
                          Select your Task
                        </MenuItem>
                        {tasks?.length > 0 && (
                          <MenuItem value="all">
                            <Checkbox
                              checked={formik.values?.tasks?.length === tasks?.length}
                              indeterminate={
                                formik.values?.tasks?.length > 0 && formik.values?.tasks?.length < tasks?.length
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {tasks?.map((task) => (
                          // console.log("milestoneNames", milestoneNames),
                          <MenuItem key={task.id} value={task.id}>
                            <Checkbox checked={formik.values?.tasks?.includes(task.id)} />
                            <ListItemText primary={task.prefix_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>Work Tasks</label>
                    <FormControl fullWidth>
                      <Select
                        id="demo-multi-select"
                        name="maintenances"
                        displayEmpty
                        multiple
                        value={formik.values.maintenances || []}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (value[value.length - 1] === "all") {
                            const selectedIds =
                              formik?.values?.maintenances?.length === maintenances.length
                                ? []
                                : maintenances.map((item) => item.id);
                            formik.setFieldValue("maintenances", selectedIds);
                            return;
                          }
                          formik.setFieldValue("maintenances", value);
                        }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          keepMounted: true,
                        }}
                        onBlur={formik.handleBlur}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span>Select Work Tasks</span>;
                          }
                          const selectedData = selected
                            .map((id) => maintenances.find((item) => item.id === id)?.maintenance_name)
                            .filter((name) => name !== undefined)
                            .join(", ");

                          return selectedData;
                        }}>
                        <MenuItem value="" disabled>
                          Select your Work Tasks
                        </MenuItem>
                        {maintenances?.length > 0 && (
                          <MenuItem value="all">
                            <Checkbox
                              checked={formik.values?.maintenances?.length === maintenances?.length}
                              indeterminate={
                                formik.values?.maintenances?.length > 0 &&
                                formik.values?.maintenances?.length < maintenances?.length
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {maintenances?.map((maintenance) => (
                          <MenuItem key={maintenance.id} value={maintenance.id}>
                            <Checkbox checked={formik.values?.maintenances?.includes(maintenance.id)} />
                            <ListItemText primary={maintenance.maintenance_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>Sequence(#)</label>
                    <TextField
                      name="sort_order"
                      // className={classes.textField}
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      placeholder="Enter Sequence Number"
                      value={formik.values.sort_order}
                      onChange={(event) => {
                        formik.setFieldValue("sort_order", event.target.value);
                        if (formik.values?.company && formik.values?.location && event.target.value) {
                          const payload = {
                            company_id: formik.values?.company,
                            location_id: formik.values?.location,
                            sort_order: event.target.value,
                            id: clickedsops,
                          };
                          matchSequenceOrder(payload);
                        } else {
                          setApiError("");
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {apiError && <span style={{ color: "red" }}>{apiError}</span>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_field_wrapper">
                    <label>Reff Doc. Version(s)</label>
                    <TextField
                      id="input-version"
                      name="version"
                      type="text"
                      InputProps={{ inputProps: { min: 0 } }}
                      placeholder="Enter Version"
                      value={formik.values.version}
                      onChange={async (e) => {
                        formik.setFieldValue("version", e.target.value);
                      }}
                      onBlur={formik?.handleBlur}
                    />
                    {formik.errors.version && formik.touched.version && (
                      <span style={{ color: "red" }}>{formik.errors.version}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form_field_wrapper">
                    <label>Description</label>
                    <div>
                      <TextareaAutosize
                        id="description"
                        className="w-100 text-area"
                        aria-label="Type Description"
                        minRows={3}
                        placeholder="Type Description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                      />
                      {formik.touched.description && formik.errors.description ? (
                        <div style={{ color: "red" }}>{formik.errors.description}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <FileInput
                  file={formik.values?.file}
                  attachment={formik.values?.attached}
                  handleBlur={formik.handleBlur}
                  name="file"
                  name2="attached"
                  setFieldValue={(fieldName, value) => formik.setFieldValue(fieldName, value)}
                />
                {formik.errors.file && formik.touched.file && (
                  <span style={{ color: "red" }}>{formik.errors.file}</span>
                )}
              </div>
            </div>
            <div className="modal_footer">
              <button
                onClick={() => {
                  handleCloseSave();
                }}
                className="btn btn-border">
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                {isAdd === true ? "Save" : "Update"}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default AddEditSops;
