import React, { useEffect, useState } from "react";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { RxPlus } from "react-icons/rx";
import "./Plant.scss";
import { HiOutlineEye } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/Model/DeleteModal";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Popover,
  gridClasses,
  styled,
} from "@mui/material";
import AddEditPlant from "../../../components/Plant/AddForm/AddEditPlant";
import { toast } from "react-toastify";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import DefaultImg from "../../../assets/img/DefaultImg.jpg";
import { useAuth } from "../../../context/AuthProvider";
import { displayDuration } from "../../../Mixin/DisplayDuration";
import { Encryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import { handleDownloadPlantExcel } from "../DownloadPlant";
import { SiMicrosoftexcel } from "react-icons/si";

const Plant = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);

  const [open, setOpen] = React.useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [plant, setPlant] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [clickedplant, setClickedplant] = useState("");
  const [editData, setEditData] = useState({});
  const [searchData, setSearchData] = useState([]);
  const [DeletePlant, setDeletePlant] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const [loading, setLoading] = useState(false);
  const { company_id, location_id } = useFilter();
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState("");
  const [page, setPage] = useState(0);
  const [columnName, setColumnName] = useState("");
  const [sortOrder, setsortOrder] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [allIds, setAllIds] = useState(0);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const fetchPlant = async () => {
    setLoading(true);
    try {
      await httpService
        .get(
          `plant/get_plant?page=${
            page + 1
          }&pageSize=${pageSize}&search=${searchData}&sortBy=${columnName}&sortOrder=${sortOrder}`
        )
        .then((response) => {
          // console.log(response.data);
          setPlant(response?.data?.data);
          // setSearchData(response?.data);
          setRowCount(response?.data?.pagination?.totalRecords);
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPlant();
  }, [
    company_id,
    location_id,
    showLoginPopup,
    page,
    pageSize,
    searchData,
    columnName,
    sortOrder,
  ]);

  const handleOpen = () => {
    setOpen(true);
    setIsAdd(true);
    setEditData("");
    setClickedplant("");
  };

  const handleClose = () => {
    setOpen(false);
    setIsAdd(false);
    setClickedplant("");
    setOpenDelete(false);
    fetchPlant();
    setEditData("");
  };

  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    // Navigate to the encrypted URL
    navigate(`view/${urlEncodedId}`);
  };

  const HandleDeletePlant = async () => {
    if (DeletePlant) {
      try {
        const response = await httpService.delete(
          `${"plant/delete_plant"}/${DeletePlant}`
        );
        if (response) {
          fetchPlant();
          navigate("/plant");
          toast.success("Plant Deleted successfully", {
            position: "top-right",
          });
        } else {
          throw response;
        }
        setOpenDelete(false);
      } catch (error) {
        setOpenDelete(false);
        console.error("Error deleting Plant:", error?.response?.data?.error);
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    }
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  const onDownloadClick = async () => {
    try {
      await handleDownloadPlantExcel(setShowLoginPopup);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };

  const handleHeaderCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const allIds = plant.map((row) => row.id); // Get all the IDs from the data
      setSelectedId(allIds); // Select all IDs
      console.log("Total selected IDs: ", allIds.length);
      setAllIds(allIds.length);
    } else {
      setSelectedId([]); // Deselect all
      console.log("Total selected IDs: 0");
    }
    setIsAllSelected(isChecked);
  };

  const deleteSelectedPlants = async () => {
    if (selectedId?.length > 0) {
      try {
        const response = await httpService.post("plant/delete_all_plant", {
          plantIds: selectedId,
        });
        if (response?.success) {
          const messages = response?.data?.combinedMessage
            ? response?.data?.combinedMessage
            : response?.data?.message;
          response?.data?.combinedMessage
            ? toast.error(messages, {
                position: "top-right",
              })
            : toast.success(messages, {
                position: "top-right",
              });
        } else {
          throw response;
        }
        setSearchData("");
        fetchPlant();
        setIsAllSelected();
        setSelectedId();
        setAllIds();
      } catch (error) {
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    } else {
      toast.error("Please select atleast one plant", {
        position: "top-right",
      });
    }
  };

  const isIdSelected = (id) => {
    return selectedId?.includes(id);
  };

  const toggleId = (id) => {
    setSelectedId((prev) => {
      if (prev && prev?.includes(id)) {
        const updatedSelectedIds = prev.filter((item) => item !== id);
        if (updatedSelectedIds.length < allIds) {
          setIsAllSelected(false);
        }
        return updatedSelectedIds;
      } else {
        const updatedSelectedIds = [...prev, id];
        if (updatedSelectedIds.length === allIds) {
          setIsAllSelected(true);
        }
        return updatedSelectedIds;
      }
    });
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="plant">
        <div className="page-title">
          <div className="title-wrap">
            <h1>Plants</h1>
          </div>
          <div className="button-wrap">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleOpen}
            >
              <RxPlus /> Add Plant
            </button>
          </div>
        </div>
        <div className="table-filter-wrap d-flex justify-content-between">
          <Button
            className="btn btn-delete"
            onClick={() => deleteSelectedPlants()}
          >
            Delete
          </Button>
          <div className="d-flex flex-row">
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDownloadClick()}
              startIcon={<SiMicrosoftexcel />}
              className="me-3"
            >
              Download
            </Button>
            <div className="header_search-wrap">
              <div className="header_search_bar common_content">
                <div className="input-group input_group">
                  <button className="btn search-btn search_btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <circle
                        cx="9.98856"
                        cy="9.98856"
                        r="8.98856"
                        stroke="#495057"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.2402 16.7071L19.7643 20.222"
                        stroke="#495057"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <input
                    type="search"
                    className="form-control form_control"
                    placeholder="Search..."
                    aria-label="Search"
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchData(value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <button type="submit" className="btn filter-btn">
              <img src={Filter} /> Filters
            </button>
          </div> */}
        </div>

        <div
          style={{
            height: `${plant?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}
        >
          <DataGrid
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              plant?.length > 0 &&
              plant?.map((plant) => ({
                ...plant,
                // id: plant.id,
              }))
            }
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "select_All",
                headerName: "Select All",
                minWidth: 150,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  return (
                    <Checkbox
                      value="Checked"
                      checked={
                        isAllSelected
                          ? true
                          : isIdSelected(params.row.id)
                          ? true
                          : false
                      }
                      onChange={() => toggleId(params.row.id)}
                    />
                  );
                },
                headerCheckboxSelection: true,
                renderHeader: (params) => {
                  return (
                    <>
                      <Checkbox
                        checked={isAllSelected}
                        onChange={(e) => handleHeaderCheckboxChange(e)}
                      />
                      <strong>Select All</strong>
                    </>
                  );
                },
              },
              {
                field: "plant_profile_img",
                headerName: "Profile Image",
                width: 150,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const { plant_profile_img } = params.row;

                  return (
                    <div className="profile-img-wrap">
                      <img
                        src={plant_profile_img ? plant_profile_img : DefaultImg} // Using DefaultImg if profileImageUrl is falsy
                        alt="profile"
                        className="profile-img"
                      />
                    </div>
                  );
                },
              },
              {
                field: "name",
                headerName: "Plant Name",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  // console.log(params.row);
                  const { name } = params.row;
                  return (
                    <div className="employee-name-weap">
                      <span>{name}</span>
                    </div>
                  );
                },
              },
              {
                field: "duration",
                headerName: "Lifetime",
                width: 250,
                renderCell: (params) => {
                  const duration = displayDuration(
                    params.row.days,
                    params.row.hours,
                    params.row.minutes
                  );
                  return <div>{duration}</div>;
                },
              },
              {
                field: "activities",
                headerName: "Activities",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  const { activities } = params.row;
                  const activity_name = activities
                    ?.map((name) => name.name || "--")
                    ?.join(", ");
                  return (
                    <div className="employee-name-weap">
                      <span>{activity_name ? activity_name : "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "milestones",
                headerName: "Milestones",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  const { milestones } = params.row;
                  const mile_name = milestones
                    ?.map((name) => name.name || "--")
                    ?.join(", ");
                  return (
                    <div className="employee-name-weap">
                      <span>{mile_name ? mile_name : "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "company_name",
                headerName: "Company",
                flex: 1,
                minWidth: 200,
                sortable: true,
                renderCell: (params) => {
                  const { company_name } = params.row;
                  return (
                    <div className="employee-name-weap">
                      <span>{company_name ? company_name : "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "location_name",
                headerName: "Location",
                flex: 1,
                minWidth: 200,
                sortable: true,
                renderCell: (params) => {
                  const { location_name } = params.row;
                  return (
                    <div className="employee-name-weap">
                      <span>{location_name ? location_name : "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "action",
                headerName: "Actions",
                headerAlign: "center",
                align: "center",
                disableColumnMenu: true,
                sortable: false,
                width: 100,
                renderCell: (params) => (
                  <>
                    <div className="action_icon-weap">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e),
                            setDataId(params.row.id),
                            setEditData(params.row);
                        }}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                    </div>
                    {anchorEl && dataId === params?.row?.id && (
                      <Popover
                        className="popup-menu"
                        key={dataId}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            viewPage(dataId);
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">View</div>
                          <HiOutlineEye style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // console.log("params", params.row.id);
                            setOpen(true);
                            setIsAdd(false);
                            setClickedplant(dataId);
                            // setEditData(params.row);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Edit</div>
                          <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenDelete(true);
                            setDeletePlant(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Delete</div>
                          <GoTrash style={{ cursor: "pointer" }} />
                        </MenuItem>
                      </Popover>
                    )}
                  </>
                ),
              },
            ]}
            initialState={{
              pagination: {
                rowCount: -1,
              },
            }}
            onSortModelChange={(e) => {
              if (e && e.length > 0) {
                setsortOrder(e[0].sort);
                setColumnName(e[0].field);
              } else {
                setsortOrder("");
                setColumnName("");
              }
            }}
            paginationMode="server"
            sortingMode="server"
            rowCount={rowCount}
            pageSizeOptions={[15, 50, 100]}
            paginationModel={{ pageSize: pageSize, page: page }}
            onPaginationModelChange={(event) => {
              setPage(event?.page);
              setPageSize(event?.pageSize);
            }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>
      {open === true && (
        <AddEditPlant
          open={open}
          isAdd={isAdd}
          clickedplant={clickedplant}
          handleClose={() => handleClose()}
          rows={editData}
          fetchPlant={() => fetchPlant()}
          companyId={company_id}
          locationId={location_id}
        />
      )}
      {openDelete && (
        <DeleteModal
          open={openDelete}
          HandleClose={() => handleClose()}
          HandleDelete={() => {
            HandleDeletePlant(), setClickedplant("");
          }}
        />
      )}
    </>
  );
};

export default Plant;
