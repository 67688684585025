import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import moment from "moment";

const handleDownloadLocationExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchLocationData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`location/get_location`)
        .then((response) => {
          if (response?.data) {
            const locationData = response?.data?.data;
            resolve(locationData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching location data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const locationData = await fetchLocationData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Location");

    // Add headers
    worksheet.addRow(["Location Name", "Company", "Description"]);
    worksheet.getRow(1).alignment = { horizontal: "center" }; // Center align the text

    if (
      locationData !== null &&
      locationData !== undefined &&
      locationData.length > 0
    ) {
      locationData.forEach((location) => {
        worksheet.addRow([
          location?.name || "",
          location?.company_name || "",
          location?.description || "",
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `LocationData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadLocationExcel };
